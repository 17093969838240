import React from 'react'
import './reviewOrderPage.css'
import './../../overlayLoader.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Section from '../../components/section/section'
import { resetMembership } from '../../redux/membership/actions'
import InjectedCheckoutForm from '../../components/checkoutForm/checkoutForm'
import { PADDING_ALL, PADDING_NO_BOTTOM } from '../../utils/styleValues'
import Btn, {
    SIZE_MEDIUM,
    DISPLAY_BLOCK,
    COLOR_ACTION,
    COLOR_ALT_SIMPLE
} from '../../components/btn/btn'
import Ak from '../../components/ak/ak'
import PaymentDetails from '../../components/paymentDetails/paymentDetails'
import MembershipSummary from '../../components/membershipSummary/membershipSummary'
import ErrorList from '../../components/errorList/errorList'
import { addOrder, setConfirmationCode } from '../../redux/orders/actions'
import utils from '../../utils/utils'
import API from '../../utils/api'
import getCardType from '../../utils/cardType'
import config from '../../config'
import ContactInfoForm from '../../components/contactInfoForm/contactInfoForm'
import Hero from '../../components/hero/hero'
import Total from '../../components/total/total'
import {
    resetCart,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerMiddleInitial,
    setCustomerTelephone,
    setCustomerEmail,
    setCustomerEmailConfirm,
    setCardName,
    setCardNumber,
    setCardType,
    setCardExpirationMonth,
    setCardExpirationYear,
    setSecurityCode,
    setBillingZipCode,
    replaceCartItem,
    setCustomerDonorBilling,
    setCustomerDonorCity,
    setCustomerDonorPostalCode,
    setCustomerDonorStateProvince,
    setCustomerDonorStreetAddress
} from '../../redux/cart/actions'
import { addError } from '../../redux/errors/actions'
import { customLoadingOverlay } from '../../styles/customLoadingOverlay';
import LoadingOverlay from 'react-loading-overlay-ts'
import { Elements } from '@stripe/react-stripe-js';

const { routes, payment } = config

class ReviewOrderPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isRequesting: false,
            paymentData: null,
            errors: null,
            editInfo: false,
            editIndex: -1,
        }
        this.api = null
        this._onEditClick = this._onEditClick.bind(this)
    }

    componentDidMount() {
        const { conf } = this.props
        this.api = new API(conf)
        if (window.location.hash === '#edit') {
            this._setEditMode()
        }
        // window.addEventListener('unload', (event) => {
        // 	dispatch(resetCart());
        // });
        setTimeout(() => {
            window.addEventListener('scrollUp', this.updateScroll.bind(this))
            this.updateScroll()
        }, 0)
    }

    componentDidUpdate() {
        this.updateScroll()
    }

    updateScroll() {
        document.getElementById('cartAAA').scrollIntoView()
        utils.scrollTop()
    }

    componentWillUnmount() {
        window.addEventListener('scrollUp', this.updateScroll.bind(this))
    }

    _setEditMode() {
        this.setState({
            editInfo: true,
        })
        document.body.classList.add('edit-mode')
        window.location.hash = 'edit'
    }

    _clearEditMode() {
        this.setState({
            editInfo: false,
        })
        document.body.classList.remove('edit-mode')
        window.location.hash = ''
    }

    _isEditMode() {
        const { editInfo } = this.state
        return editInfo
    }

    // ----------------

    _onEditSubmitContact(data) {
        const { dispatch } = this.props
        const { editIndex } = this.state
        if (data) {
            dispatch(replaceCartItem(editIndex, data))
        }
        setTimeout(() => {
            this._clearEditModeContact()
        }, 100)
    }

    _onEditCancelContact() {
        this._clearEditModeContact()
    }

    _setEditModeContact(index) {
        this.setState({
            editIndex: index,
        })
        document.body.classList.add('edit-mode')
        window.location.hash = 'edit'
    }

    _clearEditModeContact() {
        this.setState({
            editIndex: -1,
        })
        document.body.classList.remove('edit-mode')
        window.location.hash = ''
    }

    _isEditModeContact() {
        const { editIndex } = this.state
        return editIndex !== -1
    }

    _onSubmitOrderClick(e) {
        e.preventDefault()
        this.setState({
            isRequesting: true,
        })
        const { paymentData } = this.state
        try {
            if (paymentData) {
                this._makePaymentApiRequest()
            } else {
                this._submitPaymentData()
            }
        } catch (err) {
            this.setState({
                isRequesting: false,
                errors: [
                    {
                        key: 'paymentError',
                        msg: err,
                    }
                ],
            })
        }
    }

    _onAddAnotherClick(e) {
        const { dispatch, history } = this.props
        e.preventDefault()
        dispatch(resetMembership())
        utils.scrollTop()
        history.push(routes.locationSelectPage)
    }

    _submitPaymentData() {
        const { cart } = this.props
        const {
            cardName,
            customerFirstName,
            customerLastName,
            customerMiddleInitial,
            customerTelephone,
            customerDonorBilling,
            customerDonorCity,
            customerDonorPostalCode,
            customerDonorStateProvince,
            customerDonorStreetAddress,
            customerEmail,
            billingZipCode,
            items,
            stripeCreateSourceResponse,
            stripeTaxCreateSourceResponse
        } = cart
        const { id, type, card } = stripeCreateSourceResponse[0].token
        const paymentData = {
            FirstName: customerFirstName,
            MiddleInitial: customerMiddleInitial,
            LastName: customerLastName,
            DonorBilling: customerDonorBilling,
            DonorCity: customerDonorCity,
            DonorPostalCode: customerDonorPostalCode,
            DonorStateProvince: customerDonorStateProvince
                ? customerDonorStateProvince.state
                : '',
            DonorStreetAddress: customerDonorStreetAddress,
            Email: customerEmail,
            Telephone: customerTelephone,
            TokenPostalCode: billingZipCode,
            CorrelationId: id,
            Status: 'sucessful'.toLowerCase(),
            Type: ('' + card.brand).toLowerCase(),
            TokenType: type,
            TokenCardholder: cardName,
            TokenExpiration: '' + card.exp_month + card.exp_year.toString().substr(2),
            Token: card.last4,
            TokenIds: stripeCreateSourceResponse.map((x) => {
                return x.token.id
            }),
            TaxTokenIds: stripeTaxCreateSourceResponse.map((x) => {
                return x.token.id
            }),
            GiftRecipients: items.map(this._formatRecipient.bind(this)),
        }
        this.setState({
            paymentData: paymentData,
        },
            () => {
                this._makePaymentApiRequest()
            })
    }
    _handlePaymentError(status, response = {}) {
        const { t, dispatch } = this.props
        let errorMessages = null
        let messages = null
        const genericError = t('payeezyError')
        const errorTransformer = (err) => {
            return {
                key: err.code,
                msg: genericError,
            }
        }
        // show error msg
        if (response.Error && response.Error.messages) {
            messages = response.Error.messages
        } else if (response.error && response.error.messages) {
            messages = response.error.messages
        }

        if (Array.isArray(messages)) {
            errorMessages = messages.map(errorTransformer)
        } else if (utils.isObject(messages)) {
            errorMessages = []
            for (const k in messages) {
                if (messages[k]) {
                    errorMessages.push(errorTransformer(messages[k]))
                }
            }
        }
        if (!Array.isArray(errorMessages) || !errorMessages.length) {
            errorMessages = [
                {
                    key: 'unknown',
                    msg: genericError,
                }
            ]
        }
        //Clear the data
        dispatch(setCustomerFirstName(''))
        dispatch(setCustomerLastName(''))
        dispatch(setCustomerMiddleInitial(''))
        dispatch(setCustomerTelephone(''))
        dispatch(setCustomerDonorBilling(true))
        dispatch(setCustomerDonorCity(''))
        dispatch(setCustomerDonorPostalCode(''))
        dispatch(setCustomerDonorStateProvince(''))
        dispatch(setCustomerDonorStreetAddress(''))
        dispatch(setCustomerEmail(''))
        dispatch(setCustomerEmailConfirm(''))
        dispatch(setCardName(''))
        dispatch(setCardNumber(''))
        dispatch(setCardType(getCardType('')))
        dispatch(setCardExpirationMonth(''))
        dispatch(setCardExpirationYear(''))
        dispatch(setSecurityCode(''))
        dispatch(setBillingZipCode(''))

        this.setState({
            isRequesting: false,
            errors: errorMessages,
        })

        dispatch(addError({
            message: errorMessages.reduce((acc, v, i) => {
                acc += i ? '\n' + v.msg : v.msg
                return acc
            }, ''),
            buttons: [
                {
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION,
                }
            ],
        }))
    }

    _makePaymentApiRequest() {
        const { dispatch, t } = this.props
        const { paymentData } = this.state
        this.api
            .postGift(paymentData)
            .then((response) => {
                if (
                    response.errors &&
                    Array.isArray(response.errors) &&
                    response.errors.length > 0
                ) {
                    this._handleApiValidationErrors(response.errors)
                    throw Error(t('errorMessageDefault'))
                }
                if (response.data.error) {
                    this.setState({ isRequesting: false })
                    dispatch(addError({
                        message: response.data.error,
                        buttons: [
                            {
                                label: t('errorOK'),
                                onClick: 'dismiss',
                                color: COLOR_ACTION,
                            }
                        ],
                    }))
                } else {
                    this._handleApiSuccess(response.data)
                }
            })
            .catch((err) => {
                this.setState({
                    isRequesting: false,
                })
                dispatch(addError({
                    message: t('errorMessageDefault'),
                    buttons: [
                        {
                            label: t('errorOK'),
                            onClick: 'dismiss',
                            color: COLOR_ACTION,
                        }
                    ],
                }))
            })
    }

    _handleApiValidationErrors(errors) {
        const { t } = this.props
        this.setState({
            isRequesting: false,
            errors: errors.map((err, index) => {
                return {
                    key: index,
                    msg: err.message || t('genericError'),
                }
            }),
        })
    }

    _handleApiSuccess(response) {
        const { dispatch, cart, history } = this.props
        this.setState({
            errors: [],
        })
        dispatch(setConfirmationCode(response.confirmationCode))
        dispatch(addOrder(Object.freeze({
            items: cart.items.map((v) => {
                return Object.freeze(v)
            }),
            customerEmail: cart.customerEmail,
            cardName: cart.cardName,
            cardType: getCardType(cart.cardNumber),
            cardNumber: utils.anonymizeCardNumber(cart.cardNumber),
            cardExpirationMonth: cart.cardExpirationMonth,
            cardExpirationYear: cart.cardExpirationYear,
            billingZipCode: cart.billingZipCode,
        })))
        dispatch(resetCart())
        utils.scrollTop()
        history.push(routes.giverThankyouPage, { conf: this.props.conf })
    }

    _handleApiError(err) {
        this.setState({
            isRequesting: false,
            errors: [
                {
                    key: 'apiError',
                    msg: err && err.message ? err.message : 'Unknown Error',
                }
            ],
        })
    }

    _formatRecipient(recipient) {
        const {
            recipientFirstName,
            recipientLastName,
            recipientEmail,
            recipientMiddleInitial,
            recipientTelephone,
            selectedPlan,
            recipientLocation,
            deliveryDate,
            giftMessage,
            giverName,
            associates,
        } = recipient
        const cost = selectedPlan.cost
        const date = this._formatDeliveryDate(utils.reclaimDate(deliveryDate))
        return {
            FirstName: recipientFirstName,
            LastName: recipientLastName,
            Email: recipientEmail,
            MiddleInitial: recipientMiddleInitial,
            Telephone: recipientTelephone,
            PlanCost: cost,
            PromoCodeId: selectedPlan.promoCode && selectedPlan.promoCode.promoCodeId,
            PromoCodeVersionNumber:
                selectedPlan.promoCode && selectedPlan.promoCode.versionNumber,
            PlanId: selectedPlan.id,
            LocationId: recipientLocation.id,
            GiftReedemedDateTime: date,
            GiftMessage: giftMessage,
            GiverName: giverName,
            GiftRecipientAssociates: associates.map(this._formatAssociates.bind(this)),
        }
    }

    _formatAssociates(associates) {
        const { firstName, lastName, middleName } = associates
        const ass = {
            FirstName: firstName,
            LastName: lastName,
            MiddleInitial: middleName,
        }
        return ass;
    }

    _formatDeliveryDate(date) {
        const month = utils.padLeft(date.getMonth() + 1, 2, '0')
        const day = utils.padLeft(date.getDate(), 2, '0')
        const year = date.getFullYear()
        return `${month}-${day}-${year}`
    }

    _listPaymentErrors() {
        const { t } = this.props
        const { errors } = this.state
        if (Array.isArray(errors) && errors.length) {
            return <ErrorList heading={t('paymentError')} list={errors} />
        }
        return null
    }

    _onEditClick = (e) => {
        e.preventDefault()
        this.setState({
            paymentData: null,
            errors: null,
            isRequesting: false,
        })
        this._setEditMode()
    }

    _onEditCancel() {
        this._clearEditMode()
    }

    _onEditSubmit(data) {
        const { dispatch } = this.props
        if (data) {
            dispatch(setCustomerFirstName(data.customerFirstName))
            dispatch(setCustomerLastName(data.customerLastName))
            dispatch(setCustomerMiddleInitial(data.customerMiddleInitial))
            dispatch(setCustomerTelephone(data.customerTelephone))
            dispatch(setCustomerDonorBilling(data.customerDonorBilling))
            dispatch(setCustomerDonorStreetAddress(data.customerDonorStreetAddress))
            dispatch(setCustomerDonorCity(data.customerDonorCity))
            dispatch(setCustomerDonorStateProvince(data.customerDonorStateProvince))
            dispatch(setCustomerDonorPostalCode(data.customerDonorPostalCode))
            dispatch(setCustomerEmail(data.customerEmail))
            dispatch(setCustomerEmailConfirm(data.customerEmailConfirm))
            dispatch(setCardName(data.cardName))
            dispatch(setCardNumber(data.cardNumber))
            dispatch(setCardType(getCardType(data.cardNumber)))
            dispatch(setCardExpirationMonth(data.cardExpirationMonth))
            dispatch(setCardExpirationYear(data.cardExpirationYear))
            dispatch(setSecurityCode(data.securityCode))
            dispatch(setBillingZipCode(data.billingZipCode))
        }
        setTimeout(() => {
            this._clearEditMode()
        }, 100)
    }

    _onTermsClick() {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.termsPage)
    }

    _onBackToCartClick() {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.cartPage)
    }

    _onPrivacyClick() {
        const { history } = this.props
        utils.scrollTop()
        history.push(routes.privacyPage)
    }

    _renderCartTotalAll() {
        const { cart } = this.props
        return <Total items={cart} hideFees={true} />
    }

    _renderEmptyCart() {
        const { t } = this.props
        return (
            <section className="page review-order-page">
                <Section padding={PADDING_ALL}>
                    <h3 className="cart-page--empty-title">{t('emptyCartTitle')}</h3>
                    <p className="cart-page--empty-text">{t('emptyCartText')}</p>
                </Section>
            </section>
        )
    }

    _onEditMembership(index) {
        const { cart } = this.props
        const cartCount = utils.countCartItems(cart)
        if (index < cartCount && index > -1) {
            this._setEditMode(index)
        } else {
            this._clearEditMode()
        }
    }

    _onEditContactForm(index) {
        const { cart } = this.props
        const cartCount = utils.countCartItems(cart)
        if (index < cartCount && index > -1) {
            this._setEditModeContact(index)
        } else {
            this._clearEditModeContact()
        }
    }

    _renderEditContactForm() {
        const { t, cart } = this.props
        const cartCount = utils.countCartItems(cart)
        const { editIndex } = this.state
        if (editIndex < cartCount && editIndex > -1) {
            return (
                <Section padding={PADDING_ALL}>
                    <ContactInfoForm
                        data={cart.items[editIndex]}
                        hideConfirmationLabel={true}
                        saveButtonLabel={t('save')}
                        onCancel={this._onEditCancelContact.bind(this)}
                        onSubmit={this._onEditSubmitContact.bind(this)}
                    />
                </Section>
            )
        }
        return null
    }

    _renderReview() {
        const { t, cart, conf } = this.props
        const { isRequesting } = this.state
        const cartCount = utils.countCartItems(cart)
        return (
            <section className="page review-order-page">
                <LoadingOverlay active={isRequesting} spinner styles={customLoadingOverlay} >
                    <Hero title={t('reviewOrderPageTitle')} />

                    <form
                        action=""
                        name="enrollment_form"
                        encType="multipart/form-data"
                        method="post"
                        autoComplete="off"
                    >
                        <div className="section section-padding-all">
                            <div className="app--centered section--inner">
                                {cartCount > 0 ? this._renderCartTotalAll() : null}
                            </div>
                        </div>

                        <Section
                            padding={PADDING_NO_BOTTOM}
                            className="review-order-page--blurb"
                        >
                            <h3 className={'section--title'}>{t('paymentDetails')}</h3>
                        </Section>

                        <Section padding={PADDING_ALL}>
                            <PaymentDetails
                                disabled={isRequesting}
                                payment={cart}
                                onEdit={(e) => {
                                    this._onEditClick(e)
                                }}
                            />
                        </Section>

                        <Section padding={PADDING_ALL}>
                            <MembershipSummary
                                items={cart.items}
                                onEdit={this._onEditContactForm.bind(this)}
                                isPurchasePage
                            />
                            <Section
                                padding={PADDING_ALL}
                                className="checkout-page--blurb text-align-center"
                            >
                                <p>
                                    <strong>{t('reviewOrderPageBlurb')}</strong>
                                    <Ak
                                        className="review-order-page--terms-link"
                                        onClick={this._onTermsClick.bind(this)}
                                        text={t('termsAndConditions')}
                                    />
                                    .
                                </p>
                            </Section>

                            <div className="review-order-page--summary">
                                <Btn
                                    label={t('backToCart')}
                                    type="submit"
                                    disabled={isRequesting}
                                    onClick={this._onBackToCartClick.bind(this)}
                                    className={[
                                        'review-order-page--back-to-cart review-order-page--bottom-link',
                                        SIZE_MEDIUM,
                                        DISPLAY_BLOCK,
                                        COLOR_ALT_SIMPLE
                                    ]}
                                />
                                <Btn
                                    label={t('submitOrder')}
                                    type="submit"
                                    disabled={isRequesting}
                                    onClick={this._onSubmitOrderClick.bind(this)}
                                    className={[
                                        'review-order-page--submit',
                                        SIZE_MEDIUM,
                                        DISPLAY_BLOCK,
                                        COLOR_ACTION
                                    ]}
                                />
                            </div>
                        </Section>

                        <input
                            type="hidden"
                            name="apihost"
                            id="apihost"
                            payeezy-data="apihost"
                            value={conf.paymentApihost}
                        />
                        <input
                            type="hidden"
                            name="apikey"
                            id="apikey"
                            payeezy-data="apikey"
                            value={conf.paymentApikey}
                        />
                        <input
                            type="hidden"
                            name="token"
                            id="token"
                            payeezy-data="token"
                            value={conf.paymentApiToken}
                        />
                        <input
                            type="hidden"
                            name="ta_token"
                            id="ta_token"
                            payeezy-data="ta_token"
                            value={conf.paymentTAToken}
                        />
                        <input
                            type="hidden"
                            name="js_security_key"
                            id="js_security_key"
                            payeezy-data="js_security_key"
                            value={conf.paymentApiJsSecurityKey}
                        />
                        <input
                            type="hidden"
                            name="auth"
                            id="auth"
                            payeezy-data="auth"
                            value={payment.auth}
                        />
                        <input
                            type="hidden"
                            name="currency"
                            id="currency"
                            payeezy-data="currency"
                            value={payment.currency}
                        />
                        <input
                            type="hidden"
                            name="card_type"
                            id="card_type"
                            payeezy-data="card_type"
                            value={cart.cardType}
                        />
                        <input
                            type="hidden"
                            name="cardholder_name"
                            id="cardholder_name"
                            payeezy-data="cardholder_name"
                            value={cart.cardName}
                        />
                        <input
                            type="hidden"
                            name="cc_number"
                            id="cc_number"
                            payeezy-data="cc_number"
                            value={cart.cardNumber}
                        />
                        <input
                            type="hidden"
                            name="exp_month"
                            id="exp_month"
                            payeezy-data="exp_month"
                            value={cart.cardExpirationMonth}
                        />
                        <input
                            type="hidden"
                            name="exp_year"
                            id="exp_year"
                            payeezy-data="exp_year"
                            value={cart.cardExpirationYear.toString().substr(2)}
                        />
                        <input
                            type="hidden"
                            name="cvv_code"
                            id="cvv_code"
                            payeezy-data="cvv_code"
                            value={cart.securityCode || ''}
                        />
                        <input
                            type="hidden"
                            name="email"
                            id="email"
                            payeezy-data="email"
                            value={cart.customerEmail}
                        />
                        <input
                            type="hidden"
                            name="zip_postal_code"
                            id="zip_postal_code"
                            payeezy-data="zip_postal_code"
                            value={cart.billingZipCode}
                        />
                        <input
                            type="hidden"
                            name="type"
                            id="type"
                            payeezy-data="type"
                            value="home"
                        />
                        <input
                            type="hidden"
                            name="country"
                            id="country"
                            payeezy-data="country"
                            value="us"
                        />
                    </form>
                </LoadingOverlay>
            </section>
        )
    }

    _renderEditForm() {
        const { t, cart } = this.props
        return (
            <section className="page review-order-page">
                <Hero title={t('editOrderPageTitle')} />

                <Section padding={PADDING_ALL}>
                    <Elements>
                        <InjectedCheckoutForm
                            data={cart}
                            saveButtonLabel={t('save')}
                            onCancel={this._onEditCancel.bind(this)}
                            onSubmit={this._onEditSubmit.bind(this)}
                        />
                    </Elements>
                </Section>
            </section>
        )
    }

    render() {
        const { cart } = this.props
        const cartCount = utils.countCartItems(cart)
        const { editInfo, editIndex } = this.state
        if (editInfo) {
            return this._renderEditForm()
        } else if ((editIndex < cartCount && editIndex) > -1) {
            return this._renderEditContactForm()
        } else if (cartCount > 0) {
            return this._renderReview()
        } else {
            return this._renderEmptyCart()
        }
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        orders: state.orders,
        conf: state.config,
        membership: state.membership,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(ReviewOrderPage)))
