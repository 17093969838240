import React from 'react'
import './App.css'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import MaintenancePage from './pages/maintenancePage/maintenancePage'
import HomePage from './pages/homePage/homePage'
import LocationSelectPage from './pages/locationSelectPage/locationSelectPage'
import ChooseMembershipPage from './pages/chooseMembershipPage/chooseMembershipPage'
import MembershipDetailPage from './pages/membershipDetailPage/membershipDetailPage'
import ContactInfoPage from './pages/contactInfoPage/contactInfoPage'
import CartPage from './pages/cartPage/cartPage'
import CheckoutPage from './pages/checkoutPage/checkoutPage'
import ReviewOrderPage from './pages/reviewOrderPage/reviewOrderPage'
import FaqPage from './pages/faqPage/faqPage'
import GiverThankYouPage from './pages/giverThankYouPage/giverThankYouPage'
import ClaimPage from './pages/claimPage/claimPage'
import EnrollmentPage from './pages/enrollmentPage/enrollmentPage'
import ReviewEnrollmentPage from './pages/reviewEnrollmentPage/reviewEnrollmentPage'
import RecipientThankYouPage from './pages/recipientThankYouPage/recipientThankYouPage'
import PrivacyPage from './pages/privacyPage/privacyPage'
import TermsPage from './pages/termsPage/termsPage'
import Footer from './components/footer/footer'
import Toolbar from './components/toolbar/toolbar'
import ContactusPage from './pages/contactusPage/contactusPage'
import MembershipInformationPage from './pages/membershipInformation/membershipInformationPage'
import config from './config'
import utils from './utils/utils'
import { AppInsights } from 'applicationinsights-js'
import ErrorModal from './components/errorModal/errorModal'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CacheBuster from './cacheBuster'
import SearchRedemptionCode from './pages/searchRedemptionCode/searchRedemptionCode'
import EnrollmentRedemptionCodePage from './pages/enrollmentRedemptionCodePage/enrollmentRedemptionCodePage'
import ReviewRedemptionCodeEnrollment from './pages/reviewRedemptionCodeEnrollment/reviewRedemptionCodeEnrollment'
import { initGTM, PageView } from './components/tracking/tracking'

//const GiverThankYouPage = React.lazy(() => import('./pages/giverThankYouPage/giverThankYouPage'));

// import { addError } from './redux/errors/actions';
// import { COLOR_ACTION } from './components/btn/btn';

const { routes } = config

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = { stripePromise: null }
        this.currentPage = props.location.pathname
    }

    componentDidMount() {

        const { conf } = this.props

        if (conf.publishableKey) {
            const stripePromise = loadStripe(conf.publishableKey);
            this.setState({ stripePromise });
        }

        this.initializeTracking();
    }

    initializeTracking() {
        const { conf } = this.props;

        switch (conf.environment) {
            case 'dev':
                initGTM('GTM-TSSK8LL');
                break;
            case 'qa':
                initGTM('GTM-PSLZSTK');
                break;
            case 'staging':
                initGTM('GTM-TSSK8LL');
                break;
            case 'prod':
                initGTM('GTM-5LQP62R');
                break;
            default:
                initGTM('GTM-5LQP62R');
        }
        PageView();

        if (!AppInsights.config) {
            AppInsights.downloadAndSetup({
                instrumentationKey: conf.instrumentationKey,
            });
            AppInsights.startTrackPage(utils.getPageTrackingNameFromPath(this.currentPage));
        }
    }

    isClaimPage() {
        const { location } = this.props
        return location.pathname.includes(routes.claimPage)
    }

    componentDidUpdate(prevProps) {
        const { conf } = this.props

        if (conf.publishableKey !== prevProps.conf.publishableKey) {
            const stripePromise = loadStripe(conf.publishableKey);
            this.setState({ stripePromise });
        }

        if (this.props.location !== prevProps.location) {
            this.onRouteChanged()
        }
    }

    onRouteChanged() {
        const { location } = this.props
        AppInsights.stopTrackPage(utils.getPageTrackingNameFromPath(this.currentPage),
            window.location.href)
        AppInsights.startTrackPage(location.pathname)
        this.currentPage = location.pathname
        //PageView()
    }

    render() {
        const { location } = this.props
        const { stripePromise } = this.state;
        const pageDataName = location.pathname.replace(/[^0-9a-z\-_]/g, '')

        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) {
                        return null
                    }
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload()
                    }
                    return (
                        <main className="app" data-page={pageDataName || 'home'}>
                            <Toolbar environment={this.props.conf.environment} />
                            {stripePromise && (
                                <Elements stripe={stripePromise || null}>
                                    <Switch>
                                        {/* Giver - Home Page */}
                                        {this.IsInMaintenanceMode && (
                                            <Route
                                                exact
                                                path={routes.maintenancePage}
                                                component={MaintenancePage}
                                            />
                                        )}
                                        {this.IsInMaintenanceMode && this.isClaimPage() && (
                                            <Route
                                                exact
                                                path={routes.claimPage}
                                                component={MaintenancePage}
                                            />
                                        )}
                                        {/* Maintenance Page */}
                                        {!this.IsInMaintenanceMode && (
                                            <Route exact path={routes.homePage} component={HomePage} />
                                        )}
                                        {/* Giver - Location Select Page */}
                                        <Route
                                            exact
                                            path={routes.locationSelectPage}
                                            component={LocationSelectPage}
                                        />
                                        {/* Giver - Choose Membership Page */}
                                        <Route
                                            exact
                                            path={`${routes.previewMembershipPage}`}
                                            component={ChooseMembershipPage}
                                        />
                                        <Route
                                            exact
                                            path={`${routes.chooseMembershipPage}/:locationId`}
                                            component={ChooseMembershipPage}
                                        />
                                        {/* Giver - Membership Detail Page */}
                                        <Route
                                            exact
                                            path={`${routes.membershipDetailPage}/:locationId/:membershipId`}
                                            component={MembershipDetailPage}
                                        />
                                        {/* Giver - Recipient Info Form Page */}
                                        <Route
                                            exact
                                            path={routes.contactInfoPage}
                                            component={ContactInfoPage}
                                        />
                                        {/* Giver - Cart Page */}
                                        <Route exact path={routes.cartPage} component={CartPage} />
                                        {/* Giver - Checkout Page */}
                                        <Route
                                            exact
                                            path={routes.checkoutPage}
                                            //component={CheckoutPage}
                                            render={() => <CheckoutPage stripePromise={stripePromise} />}
                                        />
                                        {/* Giver - Review Order Page */}
                                        <Route
                                            exact
                                            path={routes.reviewOrderPage}
                                            component={ReviewOrderPage}
                                        />
                                        {/* Giver - Thank You Page */}
                                        <Route
                                            exact
                                            path={routes.giverThankyouPage}
                                            component={GiverThankYouPage}
                                        />
                                        {/* Recipient - Claim (PIN) */}
                                        {!this.IsInMaintenanceMode && (<Route exact path={routes.claimPage} component={ClaimPage} />)}
                                        {/* Recipient - Enrollment */}
                                        {!this.IsInMaintenanceMode && (<Route
                                            exact
                                            path={routes.enrollmentPage}
                                            component={EnrollmentPage}
                                        />)}
                                        {/* Recipient - Review Enrollment */}
                                        {!this.IsInMaintenanceMode && (<Route
                                            exact
                                            path={routes.reviewEnrollmentPage}
                                            component={ReviewEnrollmentPage}
                                        />)}
                                        {/* Recipient - Thank You Page */}
                                        <Route
                                            exact
                                            path={routes.recipientThankyouPage}
                                            component={RecipientThankYouPage}
                                        />
                                        {/* Privacy Policy */}
                                        <Route
                                            exact
                                            path={routes.privacyPage}
                                            component={PrivacyPage}
                                        />
                                        {/*  Terms & Conditions */}
                                        <Route exact path={routes.termsPage} component={TermsPage} />
                                        {/* FAQ Page */}
                                        <Route exact path={routes.faqPage} component={FaqPage} />
                                        <Route
                                            exact
                                            path={routes.contactusPage}
                                            component={ContactusPage}
                                        />
                                        <Route
                                            exact
                                            path={routes.membershipInformationPage}
                                            component={MembershipInformationPage}
                                        />
                                        {/* Redemption Code Page */}
                                        <Route
                                            exact
                                            path={routes.redemptionCode}
                                            component={SearchRedemptionCode}
                                        />
                                        <Route
                                            exact
                                            path={routes.enrollmentRedemptionCodePage}
                                            component={EnrollmentRedemptionCodePage}
                                        />
                                        <Route
                                            exact
                                            path={routes.reviewRedemptionCodeEnrollment}
                                            component={ReviewRedemptionCodeEnrollment}
                                        />
                                    </Switch>
                                </Elements>)}
                            <Footer />
                            <ErrorModal />
                        </main>
                    )
                }}
            </CacheBuster>
        )
    }

    get IsInMaintenanceMode() {
        return this.props.conf.maintenanceMode === true
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart,
        conf: state.config,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(App)))
