import React from 'react'
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { PADDING_ALL } from '../../utils/styleValues';
import Card from '../card/card';
import API from '../../utils/api';
import './promo.css';
import TextInput from '../../components/textInput/textInput';
import Btn, { COLOR_ALT_APPLY, SIZE_MEDIUM } from '../btn/btn';
import { replaceAllCartItems, setPromoCode } from '../../redux/cart/actions';

class Promo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            promoCodeCode: '',
            message: '',
            title: '',
            errorMessage: '',
            errorTitle: '',
            typeValidation: '',
            validationMessage: ''
        }

        this.api = null;
    }

    componentDidMount() {
        const { conf } = this.props;
        this.api = new API(conf);
        this.apply(false);
    }

    getCartItemProductIds = (cartItems) => {
        return cartItems.map((x) => { return x.selectedPlan.id })
    }

    getCartItemBranchIds(cartItems) {
        return cartItems.map((x) => { return x.selectedPlan.branchId })
    }

    getCartItemPromoCodes(cartItems) {
        return cartItems.filter((x) => {
            return x.selectedPlan.promoCode
        }).map((x) => {
            return x.selectedPlan.promoCode.promoCodeCode
        })
    }

    getCartProductItems(cartItems) {
        return cartItems.map((x, i) => {
            return {
                index: i,
                item: {
                    ...x,
                    recipientLocation: {
                        ...x.recipientLocation,
                    },
                    selectedClub: {
                        ...x.selectedClub,
                        plans: x.selectedClub.plans.map((m) => {
                            return {
                                ...m,
                                costAdjustments: []
                            }
                        }),
                        products: x.selectedClub.products.map((m) => {
                            return {
                                ...m,
                                costAdjustments: []
                            }
                        })
                    },
                    selectedPlan: {
                        ...x.selectedPlan,
                        netCost: x.selectedPlan.cost,
                        enrollmentFee: x.selectedPlan.enrollmentFee,
                        costPerAssociate: x.selectedPlan.costPerAssociate,
                        costAdjustments: [],
                        promoCode: null,
                        newPromoCode: null,
                    }
                }
            }
        })
    }

    getCardProductsCandidateToApplyPromoCode(cartItems, promoCodes) {
        let cartProductsCandidateToApplyPromoCodes = [];
        cartItems.forEach(cartItem => {
            let isValid = promoCodes.find(
                x => x.applyToAssoc == true
                    && cartItem.associates.length > 0
                    && x.productIds.includes(cartItem.selectedPlan.id)) != undefined;

            cartProductsCandidateToApplyPromoCodes.push(
                {
                    hasAssociates: cartItem.associates.length > 0,
                    productId: cartItem.selectedPlan.id,
                    branchId: cartItem.selectedPlan.branchId,
                    isValid: isValid
                }
            );
        });
        return cartProductsCandidateToApplyPromoCodes;
    }

    areAllPromoCodesOnlyForAssociates(promoCodes) {
        let f = promoCodes.filter(x => x.applyToAssoc == true && x.applyToPrimeMember == false && x.applyToEnrollFee == false);
        return f.length == promoCodes.length;
    }

    apply = (checkForEmpty = true) => {
        this.setState({
            typeValidation: '',
            validationMessage: ''
        });
        let validationMessage = ''
        const { t } = this.props;
        const promoCodeCode = this.state.promoCodeCode.toUpperCase()

        if (checkForEmpty && !promoCodeCode) {
            return;
        }

        const { items: cartItems } = this.props.items;

        const products = this.getCartItemProductIds(cartItems)
        const branchIds = this.getCartItemBranchIds(cartItems)
        const promoCodes = []
        if (!promoCodeCode) {
            return
        } else {
            promoCodes.push(promoCodeCode)
        }

        this.api.postSearchPromoCodesForProducts({ promoCodeCode, branchIds, promoCodes, products }).then((response) => {
            if (response.success) {
                this.props.dispatch(setPromoCode(promoCodeCode))
                const promos = [...response.data]
                let doesNotApplyAnyPromoCodeOnlyForAssoc = false;
                if (this.areAllPromoCodesOnlyForAssociates(promos)) {
                    let cartProductsCandidateToApplyPromoCodes = this.getCardProductsCandidateToApplyPromoCode(cartItems, promos);
                    let x = cartProductsCandidateToApplyPromoCodes.filter(x => x.isValid == false);
                    doesNotApplyAnyPromoCodeOnlyForAssoc = x.length == cartItems.length;
                }

                if (validationMessage !== null) {
                    this.setState({
                        typeValidation: validationMessage.type,
                        validationMessage: validationMessage.message
                    });
                }
                const cartProducts = this.getCartProductItems(cartItems)

                let promo = promos.shift()
                if (promo) {
                    if (doesNotApplyAnyPromoCodeOnlyForAssoc) {
                        this.setState({
                            typeValidation: '',
                            validationMessage: ''
                        });
                        this.setErrorMessage(t('promoCodeWithOutAssociates'), t('promoCodeWithOutAssociatesMsg'))
                    }
                    else {
                        while (promo) {
                            const { productIds, ...promoCode } = promo
                            const cartProductsForPromo = cartProducts.filter((r) => {
                                const { selectedPlan: cartProduct } = r.item
                                return productIds.includes(cartProduct.id)
                            }).map((r) => {
                                const { selectedPlan: cartProduct } = r.item
                                let netCost = cartProduct.cost
                                let enrollmentFee = cartProduct.enrollmentFee
                                let costPerAssociate = cartProduct.costPerAssociate
                                let freeAssociatesAllowedNumber = 0
                                if (promoCode.applyToPrimeMember) {
                                    if (promoCode.promoTypeCode === 'FA') {
                                        netCost = cartProduct.cost < promoCode.fixedAmount ? 0 : cartProduct.cost - promoCode.fixedAmount
                                    } else if (promoCode.promoTypeCode === 'PE') {
                                        const percentage = promoCode.percentage / 100
                                        const discount = cartProduct.cost * percentage
                                        netCost = cartProduct.cost - discount
                                    }
                                }
                                if (promoCode.applyToEnrollFee && cartProduct.enrollmentFee > 0) {
                                    if (promoCode.enrollFeePromoTypeCode === 'FA') {
                                        enrollmentFee = cartProduct.enrollmentFee < promoCode.enrollFeeFixedAmount ? 0 : cartProduct.enrollmentFee - promoCode.enrollFeeFixedAmount
                                    } else if (promoCode.enrollFeePromoTypeCode === 'PE' || promoCode.enrollFeePromoTypeCode === 'WA') {
                                        const enrollFeePercentage = promoCode.enrollFeePercentage / 100
                                        const enrollFeeDiscount = cartProduct.enrollmentFee * enrollFeePercentage
                                        enrollmentFee = cartProduct.enrollmentFee - enrollFeeDiscount
                                    }
                                }
                                if (promoCode.applyToAssoc) {
                                    if (promoCode.assocPromoTypeCode === 'FA') {
                                        costPerAssociate = cartProduct.costPerAssociate < promoCode.assocFixedAmount ? 0 : cartProduct.costPerAssociate - promoCode.assocFixedAmount
                                    } else if (promoCode.assocPromoTypeCode === 'PE') {
                                        const assocPercentage = promoCode.assocPercentage / 100
                                        const asoccPercentageDiscount = cartProduct.costPerAssociate * assocPercentage
                                        costPerAssociate = cartProduct.costPerAssociate - asoccPercentageDiscount
                                    } else if (promoCode.assocPromoTypeCode === 'FS') {
                                        freeAssociatesAllowedNumber = cartProduct.assocFreeCount
                                    }
                                }
                                cartProduct.newPromoCode = {
                                    promoCode,
                                    netCost,
                                    enrollmentFee,
                                    costPerAssociate,
                                    freeAssociatesAllowedNumber,
                                }
                                return r
                            })

                            if (promoCode.isSingleUse) {
                                let applied = false
                                // process positives
                                cartProductsForPromo.filter((r) => {
                                    const { selectedPlan: cartProduct } = r.item
                                    return cartProduct.newPromoCode.netCost >= 0
                                }).sort((a, b) => {
                                    const { selectedPlan: aCartProduct } = a.item
                                    const { selectedPlan: bCartProduct } = b.item
                                    if (promoCode.promoTypeCode === 'FA') {
                                        return aCartProduct.newPromoCode.netCost - bCartProduct.newPromoCode.netCost
                                    } else {
                                        return bCartProduct.newPromoCode.netCost - aCartProduct.newPromoCode.netCost
                                    }
                                }).forEach((r) => {
                                    const { selectedPlan: cartProduct } = r.item
                                    if (!applied && cartProduct.netCost > 0
                                        && cartProduct.netCost >= cartProduct.newPromoCode.netCost) {

                                        this.setSingleUsePromoCodeForReevaluation(cartProduct, promos, response.data)

                                        cartProduct.promoCode = cartProduct.newPromoCode.promoCode
                                        cartProduct.netCost = cartProduct.newPromoCode.netCost
                                        applied = true
                                    }
                                })
                                // process negatives
                                if (!applied) {
                                    cartProductsForPromo.filter((r) => {
                                        const { selectedPlan: cartProduct } = r.item
                                        return cartProduct.newPromoCode.netCost < 0
                                    }).sort((a, b) => {
                                        const { selectedPlan: aCartProduct } = a.item
                                        const { selectedPlan: bCartProduct } = b.item
                                        return bCartProduct.newPromoCode.netCost - aCartProduct.newPromoCode.netCost
                                    }).forEach((r) => {
                                        const { selectedPlan: cartProduct } = r.item
                                        if (!applied && (cartProduct.netCost > 0
                                            || cartProduct.netCost < cartProduct.newPromoCode.netCost)) {

                                            this.setSingleUsePromoCodeForReevaluation(cartProduct, promos, response.data)

                                            cartProduct.promoCode = cartProduct.newPromoCode.promoCode
                                            cartProduct.netCost = cartProduct.newPromoCode.netCost
                                            cartProduct.freeAssociatesAllowedNumber = cartProduct.newPromoCode.freeAssociatesAllowedNumber > 0 ?
                                                cartProduct.newPromoCode.freeAssociatesAllowedNumber : cartProduct.freeAssociatesAllowedNumber
                                            applied = true
                                        }
                                    })
                                }
                            }
                            else {
                                cartProductsForPromo.forEach((r) => {
                                    const { selectedPlan: cartProduct } = r.item
                                    if ((cartProduct.netCost > 0 && cartProduct.newPromoCode.netCost < 0)
                                        || (cartProduct.netCost > 0 && cartProduct.newPromoCode.netCost >= 0
                                            && cartProduct.netCost >= cartProduct.newPromoCode.netCost)
                                        || (cartProduct.netCost < 0 && cartProduct.newPromoCode.netCost < 0
                                            && cartProduct.netCost < cartProduct.newPromoCode.netCost)
                                        || (cartProduct.newPromoCode.promoCode.promoTypeCode === 'OT')) {

                                        this.setSingleUsePromoCodeForReevaluation(cartProduct, promos, response.data)

                                        cartProduct.promoCode = cartProduct.newPromoCode.promoCode
                                        cartProduct.netCost = cartProduct.newPromoCode.netCost
                                        cartProduct.freeAssociatesAllowedNumber = cartProduct.newPromoCode.freeAssociatesAllowedNumber > 0 ?
                                            cartProduct.newPromoCode.freeAssociatesAllowedNumber : cartProduct.freeAssociatesAllowedNumber
                                    }
                                })
                            }
                            promo = promos.shift()
                        }

                        let wasApplied = false
                        cartProducts.forEach((r) => {
                            const { selectedPlan: cartProduct } = r.item
                            if (cartProduct.netCost < 0) {
                                cartProduct.netCost = 0
                            }
                            if (promoCodeCode && cartProduct.promoCode && cartProduct.promoCode.promoCodeCode === promoCodeCode) {
                                wasApplied = true
                            }
                        })

                        if (promoCodeCode) {
                            if (wasApplied) {
                                this.setState({
                                    title: t('promoCodeCongratulations'),
                                    message: t('promoCodeSuccess'),
                                    errorTitle: '',
                                    errorMessage: '',
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            title: '',
                                            message: '',
                                        })
                                    }, 4000)
                                })
                            } else {
                                this.setErrorMessage(t('promoCodeError'), t('promoCodeNotApplied'))
                            }
                        }
                    }
                }
                this.props.doSkipScroll()

                this.props.dispatch(replaceAllCartItems(cartProducts))
            }
            else {
                if (response.errors[0].message === 'Redeemed') {
                    this.setErrorMessage(t('promoCodeRedeemed'), t('promoCodeRedeemedMsg'))
                } else if (response.errors[0].message === 'Inactive' || response.errors[0].message === 'Future') {
                    this.setErrorMessage(t('promoCodeExpired'), t('promoCodeExpiredMsg'))
                } else {
                    this.setErrorMessage(t('promoCodeInvalid'), response.errors[0].message)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    setSingleUsePromoCodeForReevaluation = (cartProduct, promos, promoCodesForProducts) => {
        if (cartProduct.promoCode && cartProduct.promoCode.isSingleUse) {
            const reevaluate = promoCodesForProducts.find((f) => {
                return f.promoCodeId === cartProduct.promoCode.promoCodeId
            })
            promos.push(reevaluate)
        }
    }

    setErrorMessage = (title, message) => {
        this.setState({
            title: '',
            message: '',
            errorTitle: title,
            errorMessage: message,
        })
    }

    render() {
        const { t } = this.props;

        return <div>
            <div className="promo--bar">
                <label htmlFor="promoCode" className="promo--promo-section">Apply promo code</label>
            </div>
            <Card padding={PADDING_ALL} className="promo">
                <div className="promo--summary">
                    <div className="promo--promo">
                        <div className="promo--promo-code-code">
                            <TextInput
                                id="promoCode"
                                className="promo--promo-code"
                                maxLength={20}
                                label={t('promoCode')}
                                value={this.state.promoCodeCode}
                                description={t('promoCodeNote')}
                                onChange={
                                    (value) => {
                                        const val = value.replace(/[^a-z0-9]/gi, '', '')
                                        this.setState({ promoCodeCode: val })
                                    }} />
                        </div>
                        <div className="promo--promo-code-message">
                            {this.state.title && <p className="promo--title-message">{this.state.title}</p>}
                            {this.state.message && <p className="promo--message">{this.state.message}</p>}
                            {this.state.errorTitle && <p className="promo--error-title-message">{this.state.errorTitle}</p>}
                            {this.state.errorMessage && <p className="promo--error-message">{this.state.errorMessage}</p>}
                        </div>
                        <div className="promo--promo-code-btn">
                            <Btn label={t('promoCodeApply')}
                                onClick={this.apply}
                                className={["promo--btn", SIZE_MEDIUM, COLOR_ALT_APPLY]} />
                        </div>
                    </div>
                </div>
                <div className="promo--summary">
                    <div className="promo--promo">
                        <div className="promo--promo-code-code">
                            {
                                (this.state.typeValidation == 1 || this.state.typeValidation == 2)
                                &&
                                <p style={{ color: this.state.typeValidation == 1 ? '#70AD47' : '#00B050', fontSize: 13, fontWeight: 500 }}>{this.state.validationMessage}</p>
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        conf: state.config,
    };
}

export default withTranslation('global')(connect(mapStateToProps)(Promo));