import React from 'react'
import './membershipItem.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Btn, { SIZE_SMALL, COLOR_ALT_SIMPLE, COLOR_ALT_FULL, COLOR_ACTION } from '../btn/btn'
import Ak from '../ak/ak'
import { removeFromCart, resetCart } from '../../redux/cart/actions'
import utils from '../../utils/utils'
import Card from '../../components/card/card'
import { PADDING_ALL } from '../../utils/styleValues'

class MembershipItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            detailsOpen: props.detailsOpen,
        }
    }

    _toggleDetails(e) {
        const { detailsOpen } = this.state
        this.setState({
            detailsOpen: !detailsOpen,
        })
        this.props.onChildToggleView()
    }

    componentDidUpdate(prevProps) {
        if (this.props.collapseAll !== prevProps.collapseAll) {
            if (this.props.collapseAll) {
                this.setState({
                    detailsOpen: false,
                })
            }
        }
    }

    _onEditClick() {
        const { onEdit, index } = this.props
        if (utils.isFunction(onEdit)) {
            onEdit(index)
        }
    }

    _onRemoveClick() {
        const { cart, dispatch, index } = this.props
        if (Array.isArray(cart.items) && index < cart.items.length) {
            if (cart.items.length === 1) {
                dispatch(resetCart())
            } else {
                dispatch(removeFromCart(index))
            }
        }
    }

    _formatSendDate(months, date) {
        if (!utils.isDateString(date)) {
            date = new Date().toISOString()
        }
        const dateParts = date.split('-')
        const year = dateParts[0]
        const month = parseInt(dateParts[1], 10) - 1
        const day = parseInt(dateParts[2], 10)
        return `${months[month]} ${day}, ${year}`
    }

    _hasAdjustments(membership) {
        return (
            membership &&
            Array.isArray(membership.costAdjustments) &&
            membership.costAdjustments.length
        )
    }

    _renderAdjustments(membership) {
        const { t } = this.props
        if (this._hasAdjustments(membership)) {
            const adjustments = []
            adjustments.push(<div key="annual_fee" className="summary-multivalue">
                <span className="summary-multivalue--label">{t('annualPrice')}</span>
                <span className="summary-multivalue--value">
                    ${membership.cost.toFixed(2)}
                </span>
            </div>)
            membership.costAdjustments.forEach((v, k) => {
                if (v && v.adjustmentDescription && v.adjustment) {
                    const adj = utils.isNumber(v.adjustment)
                        ? v.adjustment
                        : parseInt(v.adjustment, 10)
                    if (adj && !isNaN(adj)) {
                        adjustments.push(<div key={k} className="summary-multivalue">
                            <span className="summary-multivalue--label">
                                {v.adjustmentDescription}
                            </span>
                            <span className="summary-multivalue--value">
                                ${adj.toFixed(2)}
                            </span>
                        </div>)
                    }
                }
            })
            return adjustments
        }
        return null
    }

    calculateTotalAssociate(associates, selectedPlan, isPromoCode) {
        let totalAssociates = 0;
        associates.map((ass, index) => {
            if (ass.firstName !== '') {
                if (selectedPlan.newPromoCode &&
                    selectedPlan.newPromoCode.promoCode.assocPromoTypeCode) {
                    if (selectedPlan.newPromoCode.promoCode.assocPromoTypeCode !== 'FS') {
                        if (index === 0) {
                            totalAssociates = totalAssociates + (isPromoCode ? parseFloat(selectedPlan.newPromoCode.costPerAssociate) : parseFloat(selectedPlan.costPerAssociate));
                        } else {
                            totalAssociates = totalAssociates + parseFloat(selectedPlan.costPerAssociate);
                        }
                    }
                    else {
                        if (selectedPlan.newPromoCode.promoCode.assocFreeCount > index) {
                            totalAssociates = totalAssociates + parseFloat(0);
                        } else {
                            totalAssociates = totalAssociates + parseFloat(selectedPlan.costPerAssociate);
                        }
                    }

                } else {
                    if (selectedPlan.freeAssociatesAllowedNumber &&
                        selectedPlan.freeAssociatesAllowedNumber > index) {
                        totalAssociates = totalAssociates + parseFloat(0);
                    } else {
                        totalAssociates = totalAssociates + parseFloat(selectedPlan.costPerAssociate);
                    }
                }

            }
        })
        return totalAssociates;
    }
    calculateItemsGif(selectedPlan, associates, isPromoCode) {
        let membership = 0;
        let enrollmentFee = 0;
        let totalAssociates = 0;

        if (isPromoCode) {
            if (selectedPlan.newPromoCode != null) {
                membership = selectedPlan.newPromoCode.netCost;
                enrollmentFee = selectedPlan.newPromoCode.enrollmentFee;
                totalAssociates = this.calculateTotalAssociate(associates, selectedPlan, true);
            }
            totalAssociates = totalAssociates > 0 ? totalAssociates : this.calculateTotalAssociate(associates, selectedPlan, false);
        } else {
            membership = selectedPlan.cost;
            enrollmentFee = selectedPlan.enrollmentFee;
            totalAssociates = this.calculateTotalAssociate(associates, selectedPlan, false);
        }
        const itemsGif = {
            membership: membership,
            enrollmentFee: enrollmentFee,
            totalAssociates: totalAssociates,
        }
        return itemsGif;
    }
    calculateTaxGif(cartMembership, isPromoCode) {
        const { selectedClub, selectedPlan, associates } = cartMembership;
        const saleTaxRate = selectedClub.saleTaxRate == null ? 0 : selectedClub.saleTaxRate;
        const { membership, enrollmentFee, totalAssociates } = this.calculateItemsGif(selectedPlan, associates, isPromoCode)
        let totalTax = 0
        let totalGifWithoutTax = 0;
        totalGifWithoutTax = membership + enrollmentFee + totalAssociates;
        totalTax = (totalGifWithoutTax * saleTaxRate);
        return totalTax.toFixed(2);
    }

    calculateTotalGif(cartMembership, isPromoCode) {
        const { selectedPlan, associates } = cartMembership;
        const { membership, enrollmentFee, totalAssociates } = this.calculateItemsGif(selectedPlan, associates, isPromoCode)

        let totalGifWithoutTax = 0;
        let totalGifWithTax = 0;
        totalGifWithoutTax = membership + enrollmentFee + totalAssociates;

        if (isPromoCode) {
            if (selectedPlan.newPromoCode != null)
                totalGifWithTax = totalGifWithoutTax + parseFloat(this.calculateTaxGif(cartMembership, true));
        } else {
            totalGifWithTax = totalGifWithoutTax + parseFloat(this.calculateTaxGif(cartMembership, false));
        }
        return totalGifWithTax.toFixed(2);
    }

    renderTotal(membership, t) {
        if (membership.selectedPlan.newPromoCode != null && this.calculateTotalGif(membership, true) != this.calculateTotalGif(membership, false)) {
            return (
                <p className="summary-label-total">
                    <span className="summary-label-details summary-label-details-width">{t('gifTotalLabel')} : </span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width">${this.calculateTotalGif(membership, false)}</span>
                    <span className="membership-item--net-cost-discounted summary-label-details-cost-width details-cost-space">${this.calculateTotalGif(membership, true)}</span>
                </p>
            );
        } else {
            return (
                <p className="summary-label-total">
                    <span className="summary-label-details summary-label-details-width">{t('gifTotalLabel')} : </span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width"></span>
                    <span className="membership-item--net-cost summary-label-details-cost-width details-cost-space">${this.calculateTotalGif(membership, false)}</span>
                </p>
            );
        }
    }

    renderSaleTax(membership, t) {
        if (membership.selectedPlan.newPromoCode != null && this.calculateTaxGif(membership, true) != this.calculateTaxGif(membership, false)) {
            return (
                <p style={{ display: 'flex', marginTop: 0 }}>
                    <span className="summary-label-details summary-label-details-width">{t('salexTaxLabel')} :</span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width">${this.calculateTaxGif(membership, false)}</span>
                    <span className="membership-item--net-cost-discounted summary-label-details-cost-width details-cost-space">${this.calculateTaxGif(membership, true)}</span>
                </p>
            );

        } else {
            return (
                <p style={{ display: 'flex', marginTop: 0 }}>
                    <span className="summary-label-details summary-label-details-width">{t('salexTaxLabel')} :</span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width"></span>
                    <span className="membership-item--net-cost summary-label-details-cost-width details-cost-space">${this.calculateTaxGif(membership, false)}</span>
                </p>
            );
        }
    }

    renderAssociatesByName(associates) {
        const { t } = this.props
        return associates.map((ass, index) => {
            if (ass.firstName !== '') {
                return (
                    <div className="summary-associates-name" style={{ paddingBottom: 10 }}>
                        <span id={ass.id} className="summary-label-details-item">
                            {ass.firstName} {ass.middleName} {ass.lastName}
                        </span>
                    </div>
                )
            }
        })
    }

    renderAssociateByCost(associates, selectedPlan) {
        const { t } = this.props
        return associates.map((ass, index) => {
            if (ass.firstName !== '') {
                let isDiscount = false;
                let costAssociate = selectedPlan.costPerAssociate.toFixed(2);
                if (selectedPlan.newPromoCode &&
                    selectedPlan.newPromoCode.promoCode.assocPromoTypeCode) {
                    if (selectedPlan.newPromoCode.promoCode.assocPromoTypeCode !== 'FS') {
                        if (index === 0) {
                            isDiscount = true;
                            costAssociate = parseFloat(selectedPlan.newPromoCode.costPerAssociate).toFixed(2)
                        } else {
                            costAssociate = selectedPlan.costPerAssociate.toFixed(2)
                        }
                    }
                    else {
                        if (selectedPlan.newPromoCode.promoCode.assocFreeCount > index) {
                            costAssociate = parseFloat(0);
                        } else {
                            costAssociate = selectedPlan.costPerAssociate.toFixed(2);
                        }

                    }
                }
                else {
                    if (selectedPlan.freeAssociatesAllowedNumber &&
                        selectedPlan.freeAssociatesAllowedNumber > index) {
                        isDiscount = true;
                        costAssociate = parseFloat(0).toFixed(2)
                    } else {
                        costAssociate = selectedPlan.costPerAssociate.toFixed(2)
                    }
                }

                if (costAssociate != selectedPlan.costPerAssociate.toFixed(2)) {
                    return (
                        <div id={index} className="summary-associates-price summary-text-end" style={{ paddingBottom: 10 }}>
                            <span className="summary-label-details summary-label-details-width"></span>
                            <span className="summary-label-details-item-discount summary-label-details-cost-width details-cost-space">${selectedPlan.costPerAssociate.toFixed(2)}</span>
                            <span className="membership-item--net-cost-discounted summary-label-details-cost-width details-cost-space">${costAssociate}</span>
                        </div>
                    )
                } else {
                    return (
                        <div id={index} className="summary-associates-price summary-text-end" style={{ paddingBottom: 10 }}>
                            <span className="summary-label-details summary-label-details-width"></span>
                            <span className="summary-label-details-item-discount summary-label-details-cost-width details-cost-space"></span>
                            <span className="summary-label-details-item-cost summary-label-details-cost-width details-cost-space">${costAssociate}</span>
                        </div>
                    )
                }
            }
        })
    }
    existsAssociatesAndEnrollmentFee(selectedClub, associates, selectedPlan) {
        const associatesTotal = associates.length;
        const enrollmentFee = selectedPlan.enrollmentFee;
        const saleTaxRate = selectedClub.saleTaxRate == null ? 0 : selectedClub.saleTaxRate;
        return (associatesTotal == 0 && enrollmentFee == 0 && saleTaxRate == 0);
    }
    renderProduct(selectedClub, associates, selectedPlan, t) {
        const isValidate = this.existsAssociatesAndEnrollmentFee(selectedClub, associates, selectedPlan);

        return (
            <p style={{ display: 'flex', marginBottom: isValidate ? 15 : 5, marginTop: 5 }}>
                <span className="summary-label-details">{t('productLabel')} :</span>
                <span className="summary-label-details-item">{selectedPlan.name}</span>
            </p>)
    }
    renderMembership(selectedClub, associates, selectedPlan, t) {
        const isValidate = this.existsAssociatesAndEnrollmentFee(selectedClub, associates, selectedPlan);
        if (selectedPlan.newPromoCode != null && selectedPlan.newPromoCode.promoCode.applyToPrimeMember && selectedPlan.newPromoCode.promoCode.promoTypeCode != 'OT') {
            return (
                <p className="summary-label-membership" style={{ marginBottom: isValidate ? 15 : 5, marginTop: 5 }}>
                    <span className="summary-label-details summary-label-details-width">{t('gifMembershipLabel')} :</span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width">${selectedPlan.cost.toFixed(2)}</span>
                    <span className="membership-item--net-cost-discounted summary-label-details-cost-width details-cost-space">${selectedPlan.newPromoCode.netCost.toFixed(2)}</span>
                </p>)

        } else {
            return (
                <p className="summary-label-membership" style={{ marginBottom: isValidate ? 15 : 5, marginTop: 5 }}>
                    <span className="summary-label-details summary-label-details-width">{t('gifMembershipLabel')} :</span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width"></span>
                    <span className="summary-label-details-item-cost summary-label-details-cost-width details-cost-space">${selectedPlan.cost.toFixed(2)}</span>
                </p>)
        }
    }
    renderAssociateLabel(t) {
        return (
            <p style={{ display: 'flex', marginTop: 0 }}>
                <span className="summary-label-details">{t('gifAssociatesLabel')} :</span>
                <span className="summary-label-details"></span>
            </p>)
    }
    renderEnrollmentFee(selectedPlan, t) {
        if (selectedPlan.newPromoCode != null && selectedPlan.newPromoCode.promoCode.applyToEnrollFee) {
            return (
                <p className="summary-label-associate" style={{ marginTop: 0 }}>
                    <span className="summary-label-details summary-label-details-width">{t('Enrollment fee')} :</span>
                    <span className="summary-label-details-item-discount summary-label-details-cost-width details-cost-space">${selectedPlan.enrollmentFee.toFixed(2)}</span>
                    <span className="membership-item--net-cost-discounted summary-label-details-cost-width details-cost-space">${selectedPlan.newPromoCode.enrollmentFee.toFixed(2)}</span>
                </p>)

        } else {
            if (selectedPlan.enrollmentFee > 0) {
                return (
                    <p className="summary-label-associate" style={{ marginTop: 0 }}>
                        <span className="summary-label-details summary-label-details-width">{t('Enrollment fee')} :</span>
                        <span className="summary-label-details-item-discount summary-label-details-cost-width details-cost-space"></span>
                        <span className="summary-label-details-item-cost summary-label-details-cost-width details-cost-space">${selectedPlan.enrollmentFee.toFixed(2)}</span>
                    </p>)
            }
        }
    }
    renderAssociates(detailsOpen, associates, selectedPlan) {
        if (associates.length > 0) {
            return (
                <div className={detailsOpen ? 'summary-gif-section summary-gif-separator summary-gift-responsive' : 'membership-item--details-toggle'}>
                    <div className="membership-item--first-column">
                        {/* Associates */}
                        {
                            associates.length > 0 && this.renderAssociatesByName(associates)
                        }
                    </div>
                    <div className="membership-item--second-column">
                    </div>
                    <div className="membership-item--third-column">
                        {/* Associates Cost */}
                        {
                            this.renderAssociateByCost(associates, selectedPlan)
                        }
                    </div>
                </div>
            )
        }
    }

    render() {
        const { t, cartMembership, hideButtons, isPurchasePage } = this.props
        const { detailsOpen } = this.state
        const detailsLabel = detailsOpen ? t('hideDetails') : t('viewDetails')
        const {
            recipientFirstName,
            recipientMiddleInitial,
            recipientLastName,
            recipientEmail,
            selectedPlan,
            selectedClub,
            cityName,
            stateName,
            giftMessage,
            deliveryDate,
            associates,
            recipientLocation,
        } = cartMembership;
        let saleTax = selectedClub.saleTaxRate == null ? 0 : selectedClub.saleTaxRate;
        const { state } = recipientLocation;
        return (
            <>
                <Card padding={PADDING_ALL} className="membership-item">

                    <div padding={PADDING_ALL} className='summary-gif-header'>
                        <div className="membership-item--first-column summary-label-details-center summary-gif-header-top">
                            {/* Giver */}
                            <p>
                                <span className="summary-label-gif">{t('giverNameLabelSingluar')}</span>
                                <span className="summary-label-gif-for">
                                    {recipientFirstName} {recipientMiddleInitial}{' '}
                                    {recipientLastName}
                                </span>
                            </p>
                        </div>

                        <div className="membership-item--second-column summary-label-details-center summary-gif-header-top">
                            {/* Location */}
                            <p>
                                <span className="summary-label-details">{t('recipientLocationLabel')} :</span>
                                <span className="summary-label-details-item">{`${cityName}, ${state} (${stateName})`}</span>
                            </p>
                        </div>

                        <div className="membership-item--third-column summary-label-details-center summary-gif-header-top">
                            {/* Message */}
                            {detailsOpen &&
                                <>
                                    <span className="summary-label-details summary-gif-message">{t('giftMessageLabel')} :</span>
                                    <span className="summary-label-details-item summary-gif-message-details">{giftMessage}</span>
                                </>
                            }
                        </div>
                    </div>

                    {detailsOpen &&
                        <div padding={PADDING_ALL} className={detailsOpen ? 'summary-gif-section' : 'membership-item--details-toggle'}>
                            <div className="membership-item--first-column summary-label-details-center summary-email">
                                {/* Email */}
                                <p className="summary-label-details">{t('gifEmailLabel')} :</p>
                                <p className="summary-label-details-item">{recipientEmail}</p>
                            </div>
                            <div className="membership-item--second-column summary-label-details-center">
                                {/* Date */}
                                <p className="summary-label-details">{t('deliveryDateLabel')} :</p>
                                <p className="summary-label-details-item">
                                    {this._formatSendDate(utils.getMonths(t), deliveryDate)}
                                </p>
                            </div>
                        </div>
                    }

                    {detailsOpen &&
                        <div padding={PADDING_ALL} className={detailsOpen ? 'summary-gif-section summary-gift-responsive' : 'membership-item--details-toggle'}>
                            <div className="membership-item--first-column summary-label-details-center">
                                {/* Product */}
                                {this.renderProduct(selectedClub, associates, selectedPlan, t)}
                            </div>
                            <div className="membership-item--second-column summary-label-details-center">
                            </div>
                            <div className="membership-item--third-column">
                                {/* membership */}
                                {this.renderMembership(selectedClub, associates, selectedPlan, t)}
                            </div>
                        </div>
                    }

                    {detailsOpen && <div padding={PADDING_ALL} className={detailsOpen ? 'summary-gif-section summary-gift-responsive' : 'membership-item--details-toggle'}>
                            <div className="membership-item--first-column">
                                {/* Associate */}
                                {associates.length > 0 && this.renderAssociateLabel(t)}
                            </div>
                            <div className="membership-item--second-column">
                            </div>
                            <div className="membership-item--third-column">
                                {this.renderEnrollmentFee(selectedPlan, t)}
                            </div>
                        </div>
                    }
                    {detailsOpen &&
                        this.renderAssociates(detailsOpen, associates, selectedPlan)
                    }
                    {detailsOpen && saleTax > 0 &&
                        <div padding={PADDING_ALL} className={detailsOpen ? 'summary-gif-section' : 'membership-item--details-toggle'}>
                            <div className="membership-item--first-column">
                            </div>
                            <div className="membership-item--second-column">
                            </div>
                            <div className="membership-item--third-column">
                                {this.renderSaleTax(cartMembership, t)}
                            </div>
                        </div>
                    }
                    <div padding={PADDING_ALL} className='summary-gif-separator summary-gif-total summary-gift-responsive'>
                        <div className="membership-item--first-column">
                            {
                                selectedPlan.promoCode && (
                                    <p className="text-promo-applied">
                                        <span className="membership-item--promo-applied">
                                            Promo code {selectedPlan.promoCode.promoCodeCode} applied
                                        </span>
                                    </p>
                                )
                            }
                        </div>
                        <div className="membership-item--second-column">
                        </div>
                        <div className="membership-item--third-column summary-text-end">
                            {this.renderTotal(cartMembership, t)}
                        </div>
                    </div>

                    <div className="summary-gif-bottom">
                        <div className="membership-item--details-open">
                            <Ak
                                className="membership-item--details-hide"
                                onClick={this._toggleDetails.bind(this)}
                                text={detailsLabel}
                            />
                            {!isPurchasePage && (
                                <div className="membership-item--details-edit">
                                    <Btn
                                        label={t('edit')}
                                        onClick={this._onEditClick.bind(this)}
                                        className={[SIZE_SMALL, COLOR_ALT_FULL]}
                                    />
                                    <Btn
                                        label={t('Delete')}
                                        onClick={this._onRemoveClick.bind(this)}
                                        className={[SIZE_SMALL, COLOR_ACTION]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                </Card>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart,
    }
}

export default withTranslation('global')(withRouter(connect(mapStateToProps)(MembershipItem)))
