import React from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement, ElementsConsumer } from '@stripe/react-stripe-js';
import './checkoutForm.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import TextInput from '../../components/textInput/textInput';
import ErrorList from '../../components/errorList/errorList';
import Btn, { SIZE_MEDIUM, DISPLAY_BLOCK, COLOR_ACTION, COLOR_ALT_SIMPLE } from '../../components/btn/btn';
import validators, { runValidators } from '../../utils/validators';
import utils, { ZIP_REGEX } from '../../utils/utils';
import config from '../../config';
import API from '../../utils/api';
import { addError } from '../../redux/errors/actions';
import { setStripeCreateSourceResponse, setStripeTaxCreateSourceResponse } from '../../redux/cart/actions';
import { connect } from 'react-redux';
import { AppInsights } from 'applicationinsights-js';

const createOptions = (fontSize: string, placeholder: string, padding: ?string) => {
    return {
        style: {
            base: {
                height: '25px',
                fontSize: fontSize,
                color: '#424770',
                // letterSpacing: '0.025em',
                fontFamily: 'Roboto, sans-serif',
                '::placeholder': {
                    color: placeholder
                },
                ...(padding ? { padding } : {}),
            },
            invalid: {
                color: '#424770',
                // color: '#9e2146',
            },
        },
    };
};

const handleBlur = () => {
    console.log('[blur]');
};

const handleFocus = () => {
    console.log('[focus]');
};
const handleReady = () => {
    console.log('[ready]');
};

class CheckoutForm extends React.Component {

    constructor(props) {
        super(props);
        const { data, t } = props;
        this.email = React.createRef();
        this.emailConfirm = React.createRef();
        this.validators = {
            FirstName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('customerFirstNameLabel')} ${t('isRequired')}`
            }],
            LastName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('customerLastNameLabel')} ${t('isRequired')}`
            }],
            email: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('yourEmailLabel')} ${t('isRequired')}`
            },
            {
                validator: validators.isEmail,
                message: `${t('yourEmailLabel')} ${t('isInvalid')}`
            },
            {
                validator: this._compareEmails.bind(this),
                message: `${t('yourEmailLabel')} ${t('and')} ${t('yourEmailConfirmLabel')} ${t('mustMatch')}`
            }],
            emailConfirm: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('yourEmailConfirmLabel')} ${t('isRequired')}`
            },
            {
                validator: validators.isEmail,
                message: `${t('yourEmailConfirmLabel')} ${t('isInvalid')}`
            }],
            customerTelephone: [{
                validator: validators.validTelephoneOptional,
                message: `${t('customerTelephoneLabel')} ${t('digitsLeft')}`
            }],
            cardName: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('cardNameLabel')} ${t('isRequired')}`
            }, {
                validator: validators.includes(' '),
                message: `${t('fullNameRequired')}`
            }],
            cardNumber: [{
                validator: validators.isNumeric,
                message: `${t('cardNumberLabel')} ${t('isRequired')}`
            }, {
                validator: validators.isLengthLessThan(config.creditCardNumberLengthMax + 1),
                message: `${t('cardNumberLabel')} ${t('isRequired')}`
            }],
            expirationDate: [{
                validator: validators.isLength(4),
                message: `${t('cardExpirationDateLabel')} ${t('isRequired')}`,
            }, {
                validator: validators.isValidYearInFutureLastTwo(),
                message: `${t('cardExpirationDateLabel')} ${t('isRequired')}`,
            }],
            securityCode: [{
                validator: validators.isNumeric,
                message: `${t('securityCodeLabel')} ${t('isRequired')}`
            }, {
                validator: validators.isLengthLessThan(5),
                message: `${t('securityCodeLabel')} ${t('isRequired')}`
            }],
            billingZipCode: [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('zipCodeLabel')} ${t('isRequired')}`
            }, {
                validator: validators.matchesPattern(ZIP_REGEX),
                message: `${t('zipCodeFormat')}`
            }],
            city: [],
            state: [],
            zipCode: [],
            address: []
        };

        if (data.customerDonorBilling) {
            this.validators.city = [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('cityLabel')} ${t('isRequired')}`
            }]
            this.validators.state = [{
                validator: validators.isStateNullOrEmpty,
                message: `${t('stateLabel')} ${t('isRequired')}`
            }]
            this.validators.zipCode = [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('enrollmentZipCodeLabel')} ${t('isRequired')}`
            }, {
                validator: validators.matchesPattern(ZIP_REGEX),
                message: `${t('zipCodeFormat')}`
            }]
            this.validators.address = [{
                validator: validators.isNotNullOrEmpty,
                message: `${t('addressLabel')} ${t('isRequired')}`
            }]
        }

        this.state = {
            hasSubmittedForm: false,
            customerEmailsEqualError: false,
            customerFirstName: data.customerFirstName,
            customerLastName: data.customerLastName,
            customerMiddleInitial: data.customerMiddleInitial,
            customerEmail: data.customerEmail,
            customerEmailConfirm: data.customerEmailConfirm,
            customerTelephone: data.customerTelephone,
            customerDonorBilling: data.customerDonorBilling,
            customerDonorCity: data.customerDonorCity,
            customerDonorPostalCode: data.customerDonorPostalCode,
            customerDonorStateProvince: data.customerDonorStateProvince,
            customerDonorStreetAddress: data.customerDonorStreetAddress,
            cardName: data.cardName,
            cardNumber: data.cardNumber,
            cardExpirationMonth: data.cardExpirationMonth,
            cardExpirationYear: data.cardExpirationYear,
            securityCode: data.securityCode,
            billingZipCode: data.billingZipCode,
            pendingEnrollmentState: "",
            stateSuggestions: "",
            error: null,
            hasSearched: false,
            customerFirstNameError: runValidators(data.customerFirstName, this.validators.FirstName),
            customerLastNameError: runValidators(data.customerLastName, this.validators.LastName),
            customerEmailMatchError: runValidators(data.customerEmailConfirm, this.validators.email),
            customerEmailConfirmMatchError: runValidators(data.customerEmail, this.validators.emailConfirm),
            customerTelephoneError: runValidators(data.customerTelephone, this.validators.customerTelephone),
            customerDonorCityError: runValidators(data.customerDonorCity, this.validators.city),
            customerDonorStateProvinceError: runValidators(data.customerDonorStateProvince, this.validators.state),
            customerDonorPostalCodeError: runValidators(data.customerDonorPostalCode, this.validators.zipCode),
            customerDonorStreetAddressError: runValidators(data.customerDonorStreetAddress, this.validators.address),
            cardNameError: runValidators(data.cardName, this.validators.cardName),
            cardNumberError: runValidators(data.cardNumber, this.validators.cardNumber),
            cardExpirationDateError: runValidators(data.cardExpirationDate, this.validators.expirationDate),
            securityCodeError: runValidators(data.securityCode, this.validators.securityCode),
            billingZipCodeError: runValidators(data.billingZipCode, this.validators.billingZipCode),
            tokenCreationError: null,
            errorList: []
        };
        this.api = null;
        this.previousValue = '';
        this.isClearing = false;
    }

    handleChangeStripe = (change) => {
        const { t } = this.props;
        let { errorList, cardNumberError, cardExpirationDateError, securityCodeError } = this.state;
        const labels = document.getElementsByTagName('LABEL');
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].htmlFor !== '') {
                const elem = document.getElementById(labels[i].htmlFor);
                if (elem) {
                    elem.label = labels[i];
                }
            }
        }

        switch (change.elementType) {
            case 'cardNumber':
                if (change.empty || (!change.empty && !change.complete)) {
                    if (errorList.length && !errorList.filter((x) => {
                        return x.key === 'cardNumberError'
                    }).length) {
                        errorList.push({ key: 'cardNumberError', msg: `${t('cardNumberLabel')} ${t('isRequired')}` });
                        cardNumberError = `${t('cardNumberLabel')} ${t('isRequired')}`;
                    }
                } else if (!change.empty && !change.complete) {
                    errorList.push({ key: 'cardNumberError', msg: change.error ? change.error.message : `${t('cardNumberLabel')} ${t('isRequired')}` });
                    cardNumberError = `${t('cardNumberLabel')} ${t('isRequired')}`;
                } else {
                    errorList = errorList.filter((x) => {
                        return x.key !== 'cardNumberError'
                    });
                    cardNumberError = null;
                    document.getElementById('card_Number').label.style = 'color:var(--text-dark)';
                    document.getElementById('card_Number').style = 'border-color:var(--text-dark)';
                }
                break;
            case 'cardExpiry':
                if (!this.state.cardExpirationDateError) {
                    if (!change.empty && !change.complete) {
                        errorList = errorList.filter((x) => {
                            return x.key !== 'cardExpirationDateError'
                        });
                        errorList = errorList.filter((x) => {
                            return x.key !== 'tokenCreationError'
                        });
                        cardExpirationDateError = null;
                        errorList.push({ key: 'cardExpirationDateError', msg: change.error ? change.error.message : `${t('cardExpirationDateLabel')} ${t('isRequired')}` });
                        cardExpirationDateError = change.error ? change.error.message : `${t('cardExpirationDateLabel')} ${t('isRequired')}`;
                        document.getElementById('card_Expiry').label.style = 'color:var(--red)';
                        document.getElementById('card_Expiry').style = 'border-color:var(--red)';
                    } else {
                        errorList = errorList.filter((x) => {
                            return x.key !== 'cardExpirationDateError'
                        });
                        errorList = errorList.filter((x) => {
                            return x.key !== 'tokenCreationError'
                        });
                        cardExpirationDateError = null;
                        document.getElementById('card_Expiry').label.style = 'color:var(--text-dark)';
                        document.getElementById('card_Expiry').style = 'border-color:var(--text-dark)';
                    }
                }
                cardExpirationDateError = null;
                break;
            case 'cardCvc':
                if (change.empty || (!change.empty && !change.complete)) {
                    if (errorList.length && !errorList.filter((x) => {
                        return x.key === 'securityCodeError'
                    }).length) {
                        errorList.push({ key: 'securityCodeError', msg: `${t('securityCodeLabel')} ${t('isRequired')}` });
                        securityCodeError = `${t('securityCodeLabel')} ${t('isRequired')}`;
                    }
                } else if (!change.empty && !change.complete) {
                    errorList.push({ key: 'securityCodeError', msg: change.error ? change.error.message : `${t('securityCodeLabel')} ${t('isRequired')}` });
                    securityCodeError = `${t('securityCodeLabel')} ${t('isRequired')}`;
                } else {
                    errorList = errorList.filter((x) => {
                        return x.key !== 'securityCodeError'
                    });
                    securityCodeError = null;
                    document.getElementById('card_Cvc').label.style = 'color:var(--text-dark)';
                    document.getElementById('card_Cvc').style = 'border-color:var(--text-dark)';
                }
                break;
            default:
                break;
        }

        this.setState({
            errorList: errorList, cardNumberError: cardNumberError, cardExpirationDateError: cardExpirationDateError, securityCodeError: securityCodeError
        });
    };

    _compareEmails() {
        if (!this.state) {
            return true;
        }
        const customerEmail = this.state ? this.state.customerEmail : null;
        const customerEmailConfirm = this.state ? this.state.customerEmailConfirm : null;
        return (customerEmail || customerEmailConfirm) && ((customerEmail + '').toLowerCase() === (customerEmailConfirm + '').toLowerCase());
    }

    _change(prop) {
        const { onValueChange, t } = this.props;
        return (val) => {
            if (prop === 'customerTelephone') {
                const input = document.getElementById('customer_telephone');
                const start = input.selectionStart;
                this.setState({
                    [prop]: val
                }, () => {
                    const startModified = input.selectionStart;
                    if (start === 5 || (start === 4 && startModified >= 8)) {
                        input.setSelectionRange(start + 2, start + 2)
                    } else if (start === 9 || start === 10 || start === 6 || (start === 1 && startModified >= 7)) {
                        input.setSelectionRange(start + 1, start + 1)
                    } else if (start === 4 && startModified === 7) {
                        input.setSelectionRange(start + 3, start + 3)
                    } else {
                        input.setSelectionRange(start, start)
                    }
                });
            } else {
                if (prop === 'customerEmail' || prop === 'customerEmailConfirm') {
                    val = val.trim();
                }
                this.setState({
                    [prop]: val
                }, () => {
                    if (prop === 'customerEmailConfirm') {
                        this.email.current._validate(this.email.current._inputRef.value);
                    }
                    if (this.state.customerEmailMatchError === `${t('yourEmailLabel')} ${t('and')} ${t('yourEmailConfirmLabel')} ${t('mustMatch')}`) {
                        this.setState({
                            customerEmailsEqualError: true
                        })
                    } else {
                        this.setState({
                            customerEmailsEqualError: false
                        })
                    }
                });
            }
            if (utils.isFunction(onValueChange) && !/(Form|Error)$/.test(prop)) {
                onValueChange(prop, val);
            }
        };
    }

    _formatCardNumber(val = '') {
        return val ? (`${val}`).replace(/[^0-9]/g, '').substr(0, 16) : '';
    }

    _formatExpirationMonth(val = '') {
        let formatted = val.replace(/[^0-9]/g, '').substr(0, 2);
        const intVal = parseInt(formatted, 10);
        if (formatted.length === 2) {
            if (intVal < 1) {
                formatted = '01';
            } else if (intVal > 12) {
                formatted = '12';
            }
        }
        return formatted;
    }

    _formatExpirationYear(val = '') {
        const currentYear = new Date().getFullYear();
        let formatted = val.replace(/[^0-9]/g, '').substr(0, 4);
        const intVal = parseInt(formatted, 10);
        if (formatted.length === 4) {
            if (intVal < currentYear) {
                formatted = currentYear + '';
            }
        }
        return formatted;
    }

    _formatSecurityCode(val = '') {
        return val.replace(/[^0-9]/g, '').substr(0, 4);
    }

    _formatZipCode(val = '') {
        return val.substr(0, 10);
    }

    _processValidators(set) {
        const { t } = this.props;

        this.validators.city = set ? [{
            validator: validators.isNotNullOrEmpty,
            message: `${t('cityLabel')} ${t('isRequired')}`
        }] : []

        this.validators.state = set ? [{
            validator: validators.isStateNullOrEmpty,
            message: `${t('stateLabel')} ${t('isRequired')}`
        }] : []

        this.validators.zipCode = set ? [{
            validator: validators.isNotNullOrEmpty,
            message: `${t('enrollmentZipCodeLabel')} ${t('isRequired')}`
        }, {
            validator: validators.matchesPattern(ZIP_REGEX),
            message: `${t('zipCodeFormat')}`
        }] : []

        this.validators.address = set ? [{
            validator: validators.isNotNullOrEmpty,
            message: `${t('addressLabel')} ${t('isRequired')}`
        }] : []

        this._change('customerDonorStreetAddressError')(runValidators(this.state.customerDonorStreetAddress, this.validators.address))
        this._change('customerDonorCityError')(runValidators(this.state.customerDonorCity, this.validators.city))
        this._change('customerDonorStateProvinceError')(runValidators(this.state.customerDonorStateProvince, this.validators.state))
        this._change('customerDonorPostalCodeError')(runValidators(this.state.customerDonorPostalCode, this.validators.zipCode))

        if (!set) {
            this._change('customerDonorStreetAddress')('')
            this._change('customerDonorCity')('')
            this._change('customerDonorStateProvince')('')
            this.setState({ pendingEnrollmentState: '' })
            this._change('customerDonorPostalCode')('')
        }
    }

    _hasError() {
        const {
            customerFirstNameError,
            customerLastNameError,
            customerEmailMatchError,
            customerEmailConfirmMatchError,
            cardNameError,
            cardNumberError,
            cardExpirationDateError,
            securityCodeError,
            customerTelephoneError,
            customerDonorCityError,
            customerDonorStateProvinceError,
            customerDonorPostalCodeError,
            customerDonorStreetAddressError,
            billingZipCodeError
        } = this.state;
        return customerFirstNameError ||
            customerLastNameError ||
            customerEmailMatchError ||
            customerEmailConfirmMatchError ||
            cardNameError ||
            cardNumberError ||
            cardExpirationDateError ||
            securityCodeError ||
            customerTelephoneError ||
            customerDonorCityError ||
            customerDonorStateProvinceError ||
            customerDonorPostalCodeError ||
            customerDonorStreetAddressError ||
            billingZipCodeError;
    }


    handleSubmit = async (ev) => {
        const { dispatch, onSubmit, cart } = this.props;
        const tokenList = [];
        var taxTokenList = [];

        const {
            customerFirstName,
            customerLastName,
            customerMiddleInitial,
            customerEmail,
            customerEmailConfirm,
            customerTelephone,
            customerDonorBilling,
            customerDonorStreetAddress,
            customerDonorCity,
            customerDonorStateProvince,
            customerDonorStateProvinceName,
            customerDonorPostalCode,
            cardName,
            billingZipCode
        } = this.state;
        ev.preventDefault();
        this.setState({
            hasSubmittedForm: true,
        });
        this._listErrors();
        this._stripeErrors();
        const { stripe, elements } = this.props;
        if (!this._hasError() && utils.isFunction(onSubmit)) {
            if (cart && cart.items && cart.items.length) {
                if (stripe) {
                    for (const cartItem of cart.items) {
                        const cardElement = elements.getElement(CardNumberElement);
                        const { error, token } = await stripe.createToken(cardElement, { name: cardName });
                        if (error) {
                            const { errorList } = this.state;
                            utils.scrollTop();
                            errorList.push({ key: 'tokenCreationError', msg: error.message });
                            this.setState({ errorList: errorList });
                            AppInsights.trackEvent('StripeTokenCreation', {
                                code: error.code,
                                error: error.message,
                                type: error.type,
                                dateTime: new Date().getTime()
                            });
                            return;
                        }
                        else {
                            AppInsights.trackEvent('StripeTokenCreation', {
                                correlationId: token.id,
                                status: token.used,
                                type: token.type,
                                dateTime: new Date().getTime(),
                                token: token.id
                            });
                            dispatch(setStripeCreateSourceResponse(tokenList));
                            tokenList.push({ error, token });
                            if (tokenList.length === cart.items.length) {
                                dispatch(setStripeCreateSourceResponse(tokenList));
                            }
                        }

                        if (cartItem.selectedClub.saleTaxRate != null) {
                            if (cartItem.selectedClub.saleTaxRate > 0) {
                                await this._createTaxToken(cardName, taxTokenList);
                            }
                        }
                    }
                    if (tokenList.length === cart.items.length) {
                        const cardNumber = '**** **** **** ' + tokenList[0].token.card.last4;
                        const cardExpirationMonth = tokenList[0].token.card.exp_month;
                        const cardExpirationYear = tokenList[0].token.card.exp_year;
                        const securityCode = 0;
                        onSubmit({
                            customerFirstName,
                            customerLastName,
                            customerMiddleInitial,
                            customerEmail,
                            customerEmailConfirm,
                            customerTelephone,
                            customerDonorBilling,
                            customerDonorStreetAddress,
                            customerDonorCity,
                            customerDonorStateProvince,
                            customerDonorStateProvinceName,
                            customerDonorPostalCode,
                            cardName,
                            cardNumber,
                            cardExpirationMonth,
                            cardExpirationYear,
                            securityCode,
                            billingZipCode
                        });
                    }
                } else {
                    console.log("Stripe.js hasn't loaded yet.");
                }
            }
        }
        else {
            utils.scrollTop()
        }
    };

    _createTaxToken = async (cardName, taxTokenList) => {
        const { dispatch, stripe, elements } = this.props;
        const cardElement = elements.getElement(CardNumberElement);
        const { error, token } = await stripe.createToken(cardElement, { name: cardName });
        if (error) {
            const { errorList } = this.state;
            utils.scrollTop();
            errorList.push({ key: 'taxTokenCreationError', msg: error.message });
            this.setState({ errorList: errorList });
            AppInsights.trackEvent('StripeTaxTokenCreation', {
                code: error.code,
                error: error.message,
                type: error.type,
                dateTime: new Date().getTime()
            });
        }
        else {
            AppInsights.trackEvent('StripeTaxTokenCreation', {
                correlationId: token.id,
                status: token.used,
                type: token.type,
                dateTime: new Date().getTime(),
                token: token.id
            });
            taxTokenList.push({ error, token });
            dispatch(setStripeTaxCreateSourceResponse(taxTokenList));
        }
    };

    _onCancelForm() {
        const { onCancel } = this.props;
        if (utils.isFunction(onCancel)) {
            onCancel();
        }
    }

    _stripeErrors() {
        const {
            cardNumberError,
            cardExpirationDateError,
            securityCodeError
        } = this.state;

        const labels = document.getElementsByTagName('LABEL');
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].htmlFor !== '') {
                const elem = document.getElementById(labels[i].htmlFor);
                if (elem) {
                    elem.label = labels[i];
                }
            }
        }

        if (this._hasError()) {
            if (cardNumberError) {
                document.getElementById('card_Number').label.style = 'color:var(--red)';
                document.getElementById('card_Number').style = 'border-color:var(--red)';
            }
            if (cardExpirationDateError) {
                document.getElementById('card_Expiry').label.style = 'color:var(--red)';
                document.getElementById('card_Expiry').style = 'border-color:var(--red)';
            }
            if (securityCodeError) {
                document.getElementById('card_Cvc').label.style = 'color:var(--red)';
                document.getElementById('card_Cvc').style = 'border-color:var(--red)';
            }
        }
    }

    _listErrors() {
        const {
            customerFirstNameError,
            customerLastNameError,
            customerEmailMatchError,
            customerEmailConfirmMatchError,
            cardNameError,
            cardNumberError,
            cardExpirationDateError,
            securityCodeError,
            billingZipCodeError,
            customerTelephoneError,
            customerDonorStreetAddressError,
            customerDonorCityError,
            customerDonorStateProvinceError,
            customerDonorPostalCodeError,
            tokenCreationError
        } = this.state;
        const list = [];
        if (this._hasError()) {
            if (customerFirstNameError) {
                list.push({
                    key: 'customerFirstNameError',
                    msg: customerFirstNameError
                });
            }
            if (customerLastNameError) {
                list.push({
                    key: 'customerLastNameError',
                    msg: customerLastNameError
                });
            }
            if (customerEmailMatchError) {
                list.push({
                    key: 'customerEmailMatchError',
                    msg: customerEmailMatchError
                });
            }
            if (customerEmailConfirmMatchError) {
                list.push({
                    key: 'customerEmailConfirmMatchError',
                    msg: customerEmailConfirmMatchError
                });
            }
            if (customerTelephoneError) {
                list.push({
                    key: 'customerTelephoneError',
                    msg: customerTelephoneError
                });
            }
            if (customerDonorStreetAddressError) {
                list.push({
                    key: 'customerDonorStreetAddressError',
                    msg: customerDonorStreetAddressError,
                })
            }
            if (customerDonorCityError) {
                list.push({
                    key: 'customerDonorCityError',
                    msg: customerDonorCityError,
                })
            }
            if (customerDonorStateProvinceError) {
                list.push({
                    key: 'customerDonorStateProvinceError',
                    msg: customerDonorStateProvinceError,
                })
            }
            if (customerDonorPostalCodeError) {
                list.push({
                    key: 'customerDonorPostalCodeError',
                    msg: customerDonorPostalCodeError,
                })
            }
            if (cardNameError) {
                list.push({
                    key: 'cardNameError',
                    msg: cardNameError
                });
            }
            if (cardNumberError) {
                list.push({
                    key: 'cardNumberError',
                    msg: cardNumberError
                });
            }
            if (cardExpirationDateError) {
                list.push({
                    key: 'cardExpirationDateError',
                    msg: cardExpirationDateError
                });
            }
            if (securityCodeError) {
                list.push({
                    key: 'securityCodeError',
                    msg: securityCodeError
                });
            }
            if (billingZipCodeError) {
                list.push({
                    key: 'billingZipCodeError',
                    msg: billingZipCodeError
                });
            }
            if (tokenCreationError) {
                list.push({
                    key: 'tokenCreationError',
                    msg: tokenCreationError
                });
            }
        }
        this.setState({ errorList: list });
    }

    componentDidMount() {
        const { conf } = this.props;
        this.api = new API(conf);
    }

    _onStateChange(val) {
        this.setState({ pendingEnrollmentState: val });
        if (!val.length) {
            this.setState({ stateSuggestions: null });
            this.isClearing = true;
        } else if ((val && val.length >= 1) || (this.previousValue &&
            this.previousValue.length > val.length ** val.length)) {
            this.isClearing = false;
            if (this.state.customerDonorStateProvince) {
                this.setState({ customerDonorStateProvince: null });
            }
            this._requestState(val);
        }
        this.previousValue = val;
    }

    _requestState(val) {
        const { dispatch, t } = this.props;
        this.api.state(val).then((suggestions) => {
            if (Array.isArray(suggestions.data) && !this.isClearing) {
                this.setState({ stateSuggestions: suggestions.data })
            }
            this.setState({
                error: null,
                hasSearched: true
            });
        }).catch((err) => {
            this.setState({
                error: t('genericError')
            });
            dispatch(addError({
                message: t('errorMessageDefault'),
                buttons: [{
                    label: t('errorOK'),
                    onClick: 'dismiss',
                    color: COLOR_ACTION
                }]
            }));
        });
    }

    _onStateSelect(region) {
        if (region) {
            this.setState({ stateSuggestions: null, customerDonorStateProvince: region, pendingEnrollmentState: region.fullName, customerDonorStateProvinceName: region.fullName, customerDonorStateProvinceError: undefined });
        }
        const { onValueChange } = this.props;
        if (utils.isFunction(onValueChange)) {
            onValueChange('customerDonorStateProvince', region)
        }
    }

    _mapStateSuggestions(suggestion) {
        if (suggestion && suggestion.stateProvinceId) {
            return {
                name: suggestion.fullName,
                stateInitials: suggestion.state,
                id: suggestion.stateProvinceId
            };
        } else {
            return null;
        }
    }

    _renewalHandler = (e) => {
        this.setState({ customerDonorBilling: e.target.value === "true" }, () => {
            this._processValidators(this.state.customerDonorBilling)
            const { onValueChange } = this.props
            if (utils.isFunction(onValueChange)) {
                onValueChange('customerDonorBilling', this.state.customerDonorBilling)
            }
        })
    }

    render() {
        const {
            t,
            saveButtonLabel,
            cancelButtonLabel,
            onCancel
        } = this.props;
        const labelStyle = { paddingTop: '1.5rem' };
        const hasCancelButton = utils.isFunction(onCancel);
        const btnsCssClass = hasCancelButton ? 'checkout-form--btns-multi' : 'checkout-form--btns-single';
        const {
            customerFirstName,
            customerLastName,
            customerMiddleInitial,
            hasSubmittedForm,
            customerEmail,
            customerEmailConfirm,
            cardName,
            customerTelephone,
            customerDonorBilling,
            customerDonorStreetAddress,
            customerDonorCity,
            customerDonorStateProvince,
            customerDonorPostalCode,
            billingZipCode,
            customerFirstNameError,
            customerLastNameError,
            customerEmailMatchError,
            customerEmailConfirmMatchError,
            customerEmailsEqualError,
            cardNameError,
            customerTelephoneError,
            customerDonorStreetAddressError,
            customerDonorCityError,
            customerDonorStateProvinceError,
            customerDonorPostalCodeError,
            errorList,
            billingZipCodeError
        } = this.state;

        return (
            <form action=""
                className="checkout-form"
                name="recipient_contact_form"
                encType="multipart/form-data"
                method="post"
                autoComplete="off">

                <p className="required-label">* {t('requiredField')}</p>

                <ErrorList heading={t('fixMessage')} list={errorList} />

                <span className="text-input--label">
                    Where would you like the annual renewal notice sent?
                </span>
                <label className="text-input--label" >
                    <input
                        name="customer_donor_billing"
                        type="radio"
                        value="true"
                        checked={customerDonorBilling}
                        onChange={this._renewalHandler} />
                    Send annual renewal bill to me
                </label>
                <label className="text-input--label" >
                    <input
                        name="customer_recipient_billing"
                        type="radio"
                        value="false"
                        checked={!customerDonorBilling}
                        onChange={this._renewalHandler} />
                    Send annual renewal to my recipient
                </label>

                <span className="text-input--label pt-10" style={labelStyle}>
                    Gift Buyer's Name
                </span>

                <div className="checkout-form--group checkout-form--group-customer">
                    <div className="checkout-form--form-group-names">
                        <TextInput className="checkout-form--customer-first-name"
                            value={customerFirstName}
                            error={hasSubmittedForm && customerFirstNameError}
                            required={true}
                            onChange={this._change('customerFirstName').bind(this)}
                            label={t('customerFirstNameLabel')}
                            name="customer_first_name"
                            maxLength={30}
                            id="customer_first_name"
                            validators={this.validators.FirstName}
                            onValidationChange={this._change('customerFirstNameError').bind(this)} />
                        <TextInput className="checkout-form--customer-middle-initial"
                            value={customerMiddleInitial}
                            onChange={this._change('customerMiddleInitial').bind(this)}
                            label={t('customerMiddleInitialLabel')}
                            name="customer_middle_initial"
                            maxLength={1}
                            id="customer_middle_initial" />
                    </div>
                    <TextInput className="checkout-form--customer-last-name"
                        value={customerLastName}
                        error={hasSubmittedForm && customerLastNameError}
                        required={true}
                        maxLength={30}
                        onChange={this._change('customerLastName').bind(this)}
                        label={t('customerLastNameLabel')}
                        name="customer_last_name"
                        id="customer_last_name"
                        validators={this.validators.LastName}
                        onValidationChange={this._change('customerLastNameError').bind(this)} />
                </div>
                {customerDonorBilling &&
                    <span className="text-input--label pt-10" style={labelStyle}>
                        Gift Buyer's Address
                    </span>
                }
                {customerDonorBilling &&
                    <TextInput className="checkout-form--address"
                        value={customerDonorStreetAddress}
                        maxLength={150}
                        error={hasSubmittedForm && customerDonorStreetAddressError}
                        required={customerDonorBilling}
                        onChange={this._change('customerDonorStreetAddress').bind(this)}
                        label={t('addressLabel')}
                        name="customer_donor_streetaddress"
                        id="customer_donor_streetaddress"
                        validators={this.validators.address}
                        onValidationChange={this._change('customerDonorStreetAddressError').bind(this)} />
                }
                {customerDonorBilling && <div className="checkout-form--group checkout-form--group-address">
                    <TextInput className="checkout-form--city"
                        value={customerDonorCity}
                        maxLength={255}
                        error={hasSubmittedForm && customerDonorCityError}
                        required={customerDonorBilling}
                        onChange={this._change('customerDonorCity').bind(this)}
                        label={t('cityLabel')}
                        name="customer_donor_city"
                        id="customer_donor_city"
                        validators={this.validators.city}
                        onValidationChange={this._change('customerDonorCityError').bind(this)} />
                    <div className="checkout-form--form-group-state">
                        <TextInput className="checkout-form--state"
                            value={customerDonorStateProvince ? customerDonorStateProvince.fullName : this.state.pendingEnrollmentState}
                            onChange={this._onStateChange.bind(this)}
                            onSuggestionSelect={this._onStateSelect.bind(this)}
                            label={t('stateLabel')}
                            required={customerDonorBilling}
                            name="customer_donor_stateprovince"
                            id="customer_donor_stateprovince"
                            error={hasSubmittedForm && customerDonorStateProvinceError}
                            validators={this.validators.state}
                            onValidationChange={this._change('customerDonorStateProvinceError').bind(this)}
                            suggestionsMap={this._mapStateSuggestions.bind(this)}
                            suggestions={this.state.stateSuggestions} />

                        <TextInput className="checkout-form--donor-zip-code"
                            type="text"
                            value={customerDonorPostalCode}
                            error={hasSubmittedForm && customerDonorPostalCodeError}
                            required={customerDonorBilling}
                            onChange={this._change('customerDonorPostalCode').bind(this)}
                            label={t('enrollmentZipCodeLabel')}
                            maxLength={10}
                            pattern={ZIP_REGEX}
                            name="customer_donor_zip_code"
                            id="customer_donor_zip_code"
                            validators={this.validators.zipCode}
                            onValidationChange={this._change('customerDonorPostalCodeError').bind(this)} />
                    </div>
                </div>}
                <div className="checkout-form--group checkout-form--group-email">
                    {/* email */}
                    <TextInput className="checkout-form--your-email"
                        error={hasSubmittedForm && customerEmailMatchError}
                        value={customerEmail}
                        required={true}
                        maxLength={256}
                        onChange={this._change('customerEmail').bind(this)}
                        label={t('buyersEmailLabel')}
                        name="your_email"
                        id="your_email"
                        ref={this.email}
                        validators={this.validators.email}
                        description={t('yourEmailDescription')}
                        onValidationChange={this._change('customerEmailMatchError').bind(this)} />

                    {/* email confirm */}
                    <TextInput className="checkout-form--your-email-confirm"
                        error={hasSubmittedForm && (customerEmailConfirmMatchError || customerEmailsEqualError)}
                        value={customerEmailConfirm}
                        required={true}
                        maxLength={256}
                        onChange={this._change('customerEmailConfirm').bind(this)}
                        label={t('yourEmailConfirmLabel')}
                        name="your_email_confirm"
                        id="your_email_confirm"
                        ref={this.emailConfirm}
                        validators={this.validators.emailConfirm}
                        onValidationChange={this._change('customerEmailConfirmMatchError').bind(this)} />
                </div>

                <TextInput className="checkout-form--telephone"
                    maxLength={20}
                    error={hasSubmittedForm && customerTelephoneError}
                    value={customerTelephone}
                    onChange={this._change('customerTelephone').bind(this)}
                    label={t('buyersTelephoneLabel')}
                    name="customer_telephone"
                    id="customer_telephone"
                    validators={this.validators.customerTelephone}
                    onValidationChange={this._change('customerTelephoneError').bind(this)}
                />
                <h3 className="section--title">{t('securePaymentMethod')}</h3>
                <div className="checkout-form--payment-methods">
                    <img src="/images/payment/mastercard.png" alt="Mastercard" />
                    <img src="/images/payment/amex.png" alt="American Express" />
                    <img src="/images/payment/visa.png" alt="Visa" />
                    <img src="/images/payment/discover.png" alt="Discover" />
                </div>
                {/* name */}
                <TextInput className="checkout-form--name"
                    error={hasSubmittedForm && cardNameError}
                    value={cardName}
                    maxLength={50}
                    required={true}
                    onChange={this._change('cardName').bind(this)}
                    label={t('cardNameLabel')}
                    name="card_name"
                    id="card_name"
                    validators={this.validators.cardName}
                    onValidationChange={this._change('cardNameError').bind(this)} />
                <div className="text-input text-input- checkout-form--number">
                    <label htmlFor="card_Number" className="text-input--label text-input--required">
                        Card number
                    </label>
                    <CardNumberElement id="card_Number"
                        onBlur={handleBlur}
                        onChange={this.handleChangeStripe.bind(this)}
                        onFocus={handleFocus}
                        onReady={handleReady}
                        {...createOptions('1.15rem', '#fafafa')}
                    />
                </div>
                <div className="checkout-form--form-group checkout-form--date-group">
                    <div className="checkout-form--expiration-date span-labeling">
                        <label htmlFor="card_Expiry" className="text-input--label text-input--required">
                            Expiration Date
                        </label>
                        <CardExpiryElement id="card_Expiry"
                            onBlur={handleBlur}
                            onChange={this.handleChangeStripe.bind(this)}
                            onFocus={handleFocus}
                            onReady={handleReady}
                            {...createOptions('1.15rem', '#fafafa')}
                        />
                    </div>
                    <div className="checkout-form--security-code span-labeling">
                        <span>
                            <label htmlFor="card_Cvc" className="text-input--label ">
                                <span className="text-input--required">Security Code</span>
                            </label>
                        </span>
                        <CardCvcElement id="card_Cvc"
                            onBlur={handleBlur}
                            onChange={this.handleChangeStripe.bind(this)}
                            onFocus={handleFocus}
                            onReady={handleReady}
                            {...createOptions('1.15rem', '#fafafa')}
                        />
                    </div>
                </div>
                <div className="checkout-form--form-group checkout-form--billing-group">
                    {/* zip code */}
                    <TextInput
                        className="checkout-form--zip-code"
                        type="text"
                        error={hasSubmittedForm && billingZipCodeError}
                        value={this._formatZipCode(billingZipCode)}
                        required={true}
                        maxLength={10}
                        pattern={ZIP_REGEX}
                        onChange={this._change('billingZipCode').bind(this)}
                        label={t('zipCodeLabel')}
                        labelOverflow={true}
                        name="zip_code"
                        id="zip_code"
                        validators={this.validators.billingZipCode}
                        onValidationChange={this._change('billingZipCodeError').bind(this)} />
                </div>
                <div className={`checkout-form--btns ${btnsCssClass}`}>
                    <Btn label={saveButtonLabel || t('reviewOrder')}
                        type="submit"
                        onClick={this.handleSubmit.bind(this)}
                        className={[
                            'checkout-form--submit',
                            SIZE_MEDIUM,
                            DISPLAY_BLOCK,
                            COLOR_ACTION
                        ]} />
                    {hasCancelButton ? (
                        <Btn label={cancelButtonLabel || t('cancel')}
                            type="button"
                            onClick={this._onCancelForm.bind(this)}
                            className={['checkout-form--cacncel',
                                SIZE_MEDIUM,
                                DISPLAY_BLOCK,
                                COLOR_ALT_SIMPLE
                            ]} />
                    ) : null}
                </div>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        membership: state.membership,
        cart: state.cart,
        conf: state.config,
    };
}

//export default withTranslation('global')(injectStripe(withRouter(connect(mapStateToProps)(CheckoutForm))));

const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} {...props} />
        )}
    </ElementsConsumer>
);

export default withTranslation('global')(withRouter(connect(mapStateToProps)(InjectedCheckoutForm)));
